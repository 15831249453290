import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { put } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'

class ReactivatePage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.password) {
      this.input.password.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/login/reactivate/${this.props.match.params.email}/${this.props.match.params.key}/`, {
      password: encrypt(this.input.password.value()),
      confirm: encrypt(this.input.confirm.value()),
      email: this.props.match.params.email,
      token: this.props.match.params.key,
      hasEncrypt: 'true'
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    const alerts = []

    if (this.state.success) {
      alerts.push(
        <div key='success'>
          <div className='alert alert-success'>
            {t('password_reset')}
          </div>
          <Link to='/app/' className='btn btn-link'>{t('back_login')}</Link>
        </div>)
    }

    if (this.state.error) {
      alerts.push(
        <div key='danger' className='alert alert-danger'>
          {this.state.error}
        </div>)
    }

    return (
      <section className='container'>
        <Helmet>
          <title>{t('reactivate_account')}</title>
        </Helmet>
        <div className='container'>
          <h1 className='page-header'>{t('reactivate_account')}</h1>
          <div className='row'>
            <div className='col-sm-6'>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alerts}
                <TextControl
                  ref={(c) => (this.input.password = c)}
                  name='password'
                  label={t('new_password')}
                  type='password'
                  hasError={this.state.hasError.password}
                  help={this.state.help.password}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.confirm = c)}
                  name='confirm'
                  label={t('confirm_new_password')}
                  type='password'
                  hasError={this.state.hasError.confirm}
                  help={this.state.help.confirm}
                  disabled={this.state.loading}
                />
                <TextControl
                  name='_key'
                  label={t('key')}
                  hasError={this.state.hasError.key}
                  value={this.props.match.params.key}
                  help={this.state.help.key}
                  disabled
                />
                <TextControl
                  name='_email'
                  label='Email'
                  hasError={this.state.hasError.email}
                  value={this.props.match.params.email}
                  help={this.state.help.email}
                  disabled
                />
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    disabled={this.state.loading}
                  >
                    {t('submit')}
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                  <Link to='/app/' className='btn btn-link'>{t('back_login')}</Link>
                </ControlGroup>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ReactivatePage
