import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { put, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import DateTimeControl from '../components/datetime-control'
import SelectControl from '../components/select-control'

class EditNewsPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      lang: '',
      postTime: new Date(),
      content: ''
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleChange (event, editor) {
    const content = editor.getData()
    this.setState({ content: content })
  }

  fetchData () {
    get(`/1/news/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          id: r.data._id,
          name: r.data.name,
          lang: r.data.lang,
          postTime: new Date(r.data.postTime),
          content: r.data.content,
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/news/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      lang: this.input.lang.value(),
      type: 1,
      postTime: this.state.postTime.getTime(),
      content: this.state.content,
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/news' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    let postDate = new Date(this.state.postTime)
    postDate = postDate.getFullYear() + '.' + ('0' + (postDate.getMonth() + 1)).substr(-2) + '.' + ('0' + postDate.getDate()).substr(-2)

    let uri = '/app/1/content/storage'
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = '/1/content/storage'
    }

    return (
      <Container fluid>
        <Helmet>
          <title>編輯產品新訊 {this.state.name}</title>
        </Helmet>
        <h1 className='page-header'>編輯產品新訊</h1>
        <Row>
          <Col md='6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='標題'
                placeholder='新聞標題'
                value={this.state.name}
                onChange={(e) => (this.setState({ name: e.target.value }))}
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />

              <SelectControl
                ref={(c) => (this.input.lang = c)}
                name='lang'
                label='語系'
                value={this.state.lang}
                onChange={(e) => (this.setState({ lang: e.target.value }))}
                hasError={this.state.hasError.lang}
                help={this.state.help.lang}
                disabled={this.state.loading}
              >
                <option value='tw'>
                  繁體中文
                </option>
                <option value='en'>
                  英文
                </option>
              </SelectControl>

              <DateTimeControl
                ref={(c) => (this.input.postTime = c)}
                name='postTime'
                label='發布日期'
                placeholder='新聞發布日期'
                timeFormat={false}
                value={this.state.postTime}
                onChange={(e) => this.setState({ postTime: new Date(e.valueOf()) })}
                hasError={this.state.hasError.postTime}
                help={this.state.help.postTime}
                disabled={this.state.loading}
              />

              <div className='form-group'>
                <label className='control-label'>
                  內文
                </label>
                <div className='news'>
                  <CKEditor
                    className='news'
                    config={{
                      ckfinder: {
                        uploadUrl: uri
                      }
                    }}
                    editor={ClassicEditor}
                    data={this.state.content}
                    onInit={editor => {}}
                    onChange={this.handleChange.bind(this)}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </div>
              </div>

              <SelectControl
                ref={(c) => (this.input.isActive = c)}
                name='isActive'
                label='是否啟用'
                value={this.state.isActive}
                onChange={(e) => (this.setState({ isActive: e.target.value }))}
                hasError={this.state.hasError.isActive}
                help={this.state.help.isActive}
                disabled={this.state.loading}
              >
                <option value='true'>
                  啟用
                </option>
                <option value='false'>
                  關閉
                </option>
              </SelectControl>
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  更新
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </Col>
          <Col md='6'>
            <Card>
              <CardBody className='news'>
                <h2>{this.state.name}</h2>
                <p className='post-time'>{postDate}</p>
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EditNewsPage
