import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, deleteItem } from '../utils/httpAgent'
import config from '../config'

class ShareholdersPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchShareholderList = this.handleFetchShareholderList.bind(this)
  }

  handleFetchShareholderList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/shareholders?page=${page}&limit=${limit}&type=1&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            if (item.recordDate) {
              item.recordDate = moment(item.recordDate).format('YYYY/MM/DD')
            }
            if (item.exDividendDate) {
              item.exDividendDate = moment(item.exDividendDate).format('YYYY/MM/DD')
            }
            if (item.cashDividendPaymentDate) {
              item.cashDividendPaymentDate = moment(item.cashDividendPaymentDate).format('YYYY/MM/DD')
            }
            if (item.stockDividendPaymentDate) {
              item.stockDividendPaymentDate = moment(item.stockDividendPaymentDate).format('YYYY/MM/DD')
            }
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteFetchFinancial (uid) {
    if (!window.confirm('是否確定要刪除此年度股東服務資訊？')) {
      return -1
    }

    deleteItem(`/1/admin/shareholder/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchShareholderList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: 'years',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/shareholder/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '年度',
        accessor: 'years'
      }, {
        Header: '現金股利',
        accessor: 'chashDividend'
      }, {
        Header: '股票股利',
        accessor: 'stockDividend'
      }, {
        Header: '除權息基準日',
        accessor: 'recordDate'
      }, {
        Header: '除權息交易日',
        accessor: 'exDividendDate'
      }, {
        Header: '現金發放日',
        accessor: 'cashDividendPaymentDate'
      }, {
        Header: '股票發放日',
        accessor: 'stockDividendPaymentDate'
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteFetchFinancial.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            股東服務資訊管理
          </title>
        </Helmet>

        <h1 className='page-header'>股東服務資訊管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to='/app/admin/create_shareholder' className='btn btn-success'>
              建立年度股東服務資訊
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchShareholderList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-6' style={{ padding: '20px' }}>
                    { row.original.meetingNotice.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.meetingNotice.tw}`} target='_blank' rel='noopener noreferrer'>
                          開會通知 (繁體中文)
                        </a>
                      </p>
                    }
                    { row.original.meetingAgenda.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.meetingAgenda.tw}`} target='_blank' rel='noopener noreferrer'>
                          會議事手冊 (繁體中文)
                        </a>
                      </p>
                    }
                    { row.original.meetingMinutes.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.meetingMinutes.tw}`} target='_blank' rel='noopener noreferrer'>
                          會議事錄 (繁體中文)
                        </a>
                      </p>
                    }
                  </div>

                  <div className='col-md-6' style={{ padding: '20px' }}>
                    { row.original.meetingNotice.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.meetingNotice.en}`} target='_blank' rel='noopener noreferrer'>
                          開會通知 (英文)
                        </a>
                      </p>
                    }
                    { row.original.meetingAgenda.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.meetingAgenda.en}`} target='_blank' rel='noopener noreferrer'>
                          會議事手冊 (英文)
                        </a>
                      </p>
                    }
                    { row.original.meetingMinutes.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.meetingMinutes.en}`} target='_blank' rel='noopener noreferrer'>
                          會議事錄 (英文)
                        </a>
                      </p>
                    }
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'years', desc: true }]}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default ShareholdersPage
