import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'reactstrap'
import axios from 'axios'
import { get, put } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import TextareaControl from '../components/textarea-control'
import { debounce } from 'lodash'

const DownloadCertificatePage = ({ t }) => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const [certificateId, setCertificateId] = useState('')
  const [licenseDuration, setLicenseDuration] = useState('')
  const [maxServers, setMaxServers] = useState('')
  const [macAddressList, setMacAddressList] = useState('')
  const [totalMaxStreams, setTotalMaxStreams] = useState('')

  useEffect(() => {
    get('/1/account/certificate/self').then((r) => {
      if (r.success) {
        setSuccess(true)
        setCertificateId(r.data._id)
        setLicenseDuration(r.data.licenseDuration)
        setMaxServers(r.data.maxServers)
        setMacAddressList(r.data.macAddressList ? r.data.macAddressList.join(',') : '')
        setTotalMaxStreams(r.data.totalMaxStreams)
      } else {
        setSuccess(false)
        setError(t('no_certificate_available'))
      }
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    put('/1/account/certificate/self', {
      macAddressList: macAddressList ? macAddressList.split(',') : null
    }).then((r) => {
      if (r.success === true) {
        setError('')
        setLoading(false)
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        setError(state.error)
        setHasError(state.hasError)
        setHelp(state.help)
        setLoading(false)
      }
    })
  }

  const downloadCertificate = () => {
    axios({
      url: `/1/account/certificate/${certificateId}/download`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'certificate.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  const validateMacAddressList = debounce((value) => {
    const hasError = {}
    const help = {}

    // 檢查 mac address 是否有重複或者格式錯誤
    const macAddressSet = new Set()
    for (const macAddress of value.split(',')) {
      if (macAddressSet.has(macAddress)) {
        hasError.macAddressList = true
        help.macAddressList = t('duplicate_mac_address')
        break
      }
      macAddressSet.add(macAddress)

      if (!macAddress.match(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/)) {
        hasError.macAddressList = true
        help.macAddressList = t('invalid_mac_address_format')
        break
      }
    }

    if (value.split(',').length > maxServers) {
      hasError.macAddressList = true
      help.macAddressList = t('exceeded_max_number_of_servers')
    }

    if (hasError.macAddressList) {
      setHasError(hasError)
      setHelp(help)
    } else {
      setHasError({ macAddressList: false })
      setHelp({ macAddressList: '' })
    }
  }, 2000)

  let alert = []

  if (error) {
    alert = <Alert type='danger' message={error} />
  }

  return (
    <section className='section-home container'>
      <Helmet>
        <title>{t('patrol_certificate')}</title>
      </Helmet>

      <h1 className='page-header'>{t('patrol_certificate')}</h1>
      <Row>
        <Col md={12}>
          {alert}
        </Col>
      </Row>
      {success &&
        <Row>
          <Col md={12}>
            {licenseDuration && <p>{t('validity_period')}: {licenseDuration}</p>}
            {maxServers && <p>{t('max_number_of_servers')}: {maxServers}</p>}
            {totalMaxStreams && <p>{t('max_number_of_cameras')}: {totalMaxStreams}</p>}
          </Col>
          <Col md={6}>
            {maxServers &&
              <TextareaControl
                name='macAddressList'
                label={t('supported_mac_address_list')}
                hasError={hasError.macAddressList}
                help={help.macAddressList}
                disabled={loading}
                value={macAddressList}
                onChange={(e) => {
                  setMacAddressList(e.target.value)
                  validateMacAddressList(e.target.value)
                }}
              />}
            {maxServers &&
              <Button
                type='submit'
                inputClasses={{ 'btn-success mr-3': true }}
                disabled={loading || macAddressList.split(',').length > maxServers}
                onClick={handleSubmit}
              >
                {t('update')}
                <Spinner space='left' show={loading} />
              </Button>}
            <button
              className='btn btn-primary'
              onClick={downloadCertificate}
            >
              {t('download_certificate')}
            </button>
          </Col>
        </Row>}
    </section>
  )
}

export default DownloadCertificatePage
