import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, put, deleteItem } from '../utils/httpAgent'

class ProductsPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchProductsList = this.handleFetchProductsList.bind(this)
  }

  handleFetchProductsList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : [{ id: 'createTime', desc: true }]
    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/product_categories?page=${page}&limit=${limit}&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')

            item.product.forEach((product, i) => {
              product.no = i
              product.isLast = (i + 1) === item.product.length
              product.cid = item._id
              product.updateTime = moment(product.updateTime).format('YYYY/MM/DD')
              product.createTime = moment(product.createTime).format('YYYY/MM/DD')
            })
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteProductCategory (uid, name) {
    if (!window.confirm(`是否確定要刪除 ${name} 產品線？`)) {
      return -1
    }

    deleteItem(`/1/admin/product_category/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchProductsList()
        }
      })
  }

  changeProductOrder (cid, pid, order) {
    put(`/1/admin/product_category/${cid}/product/${pid}/${order}`)
      .then(r => {
        if (r.success === true) {
          const categoryIndex = this.state.data.findIndex(pc => pc._id === cid)
          const category = this.state.data
          category[categoryIndex] = r.data
          category[categoryIndex].product.forEach((product, i) => {
            product.no = i
            product.isLast = (i + 1) === category[categoryIndex].product.length
            product.cid = cid
            product.updateTime = moment(product.updateTime).format('YYYY/MM/DD')
            product.createTime = moment(product.createTime).format('YYYY/MM/DD')
          })
          this.setState({ data: category })
        }
      })
  }

  deleteProduct (uid, name) {
    if (!window.confirm(`是否確定要刪除 ${name} 產品？`)) {
      return -1
    }

    deleteItem(`/1/admin/product/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchProductsList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/product_category/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '名稱',
        accessor: 'name'
      }, {
        Header: '客戶群組',
        accessor: 'userRoles',
        Cell: row => (row.value.map(key => (
          {
            client: '一般客戶',
            'client-gaia': '蓋亞客戶'
          }[key]
        )).join(', '))
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteProductCategory.bind(this, row.value, row.original.name)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    const columns2 = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 115,
        Cell: row => (
          <span>
            <Link to={`/app/admin/product/${row.value}`} className='btn btn-sm mr-2'>
              <i className='lnr lnr-pencil' />
            </Link>
            {row.original.no !== 0 &&
              <button className='btn btn-sm' onClick={this.changeProductOrder.bind(this, row.original.cid, row.value, 'up')}>
                <i className='lnr lnr-chevron-up' />
              </button>}

            {!row.original.isLast &&
              <button className='btn btn-sm' onClick={this.changeProductOrder.bind(this, row.original.cid, row.value, 'down')}>
                <i className='lnr lnr-chevron-down' />
              </button>}
          </span>
        )
      }, {
        Header: '產品名稱',
        accessor: 'name',
        width: 255,
        Cell: row => (
          <Link to={`/app/admin/product/${row.original._id}/files`} className='btn'>
            {row.value}
          </Link>
        )
      }, {
        Header: '綁定域名',
        accessor: 'emailDomain'
      }, {
        Header: '連絡人 mail',
        accessor: 'principalMail'
      }, {
        Header: '開放申請',
        accessor: 'display',
        width: 80,
        Cell: row => (
          <span>
            {row.value === true ? '開放'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteProduct.bind(this, row.value, row.original.name)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            產品線管理
          </title>
        </Helmet>

        <h1 className='page-header'>產品線管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to='/app/admin/create_product_category' className='btn btn-success'>
              建立產品線
            </Link>
            &nbsp;
            <Link to='/app/admin/create_product' className='btn btn-success'>
              建立產品
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchProductsList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    <ReactTable
                      data={row.original.product}
                      columns={columns2}
                      defaultPageSize={row.original.product.length}
                      showPagination={false}
                    />
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'createTime', desc: true }]}
            />
          </div>
        </div>

      </section>
    )
  }
}

export default ProductsPage
