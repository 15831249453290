import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { Container, Row, Col } from 'reactstrap'
import { get, deleteItem } from '../../utils/httpAgent'
import SelectControl from '../../components/select-control-lite'

class FAQPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0,
      pageSize: 10,
      type: '0',
      lang: ''
    }
    this.handleFetchDataList = this.handleFetchDataList.bind(this)
  }

  search (filters) {
    this.setState(filters)
    this.handleFetchDataList({
      page: 0,
      pageSize: this.state.pageSize,
      type: filters.type ? filters.type : this.state.type,
      lang: filters.lang ? filters.lang : this.state.lang
    })
  }

  handleFetchDataList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    const type = state && state.type ? state.type : this.state.type
    const lang = state && state.lang !== undefined ? state.lang : this.state.lang
    let sorted = state ? state.sorted ? state.sorted : [] : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/faqs?page=${page}&limit=${limit}&sort=${sorted}&type=${type}&lang=${lang}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteData (uid) {
    if (!window.confirm('是否確定要刪除此問題？')) {
      return -1
    }

    deleteItem(`/1/admin/faq/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchDataList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/faq/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '標題',
        accessor: 'name'
      // }, {
      //   Header: '類型',
      //   accessor: 'type',
      //   width: 80,
      //   Cell: row => (
      //     <span>
      //       {row.value === 1 ? '帳戶註冊' : row.value === 2 ? '軟體安裝' : row.value === 3 ? '訂閱方案' : row.value === 4 ? '軟體介紹' : row.value === 5 ? '相機連線' : row.value === 6 ? '軟體使用教學' : '未設定'}
      //     </span>
      //   )
      }, {
        Header: '順序',
        accessor: 'no'
      }, {
        Header: '語系',
        accessor: 'lang',
        width: 80,
        Cell: row => (
          <span>
            {row.value === 'tw' ? '繁中' : row.value === 'en' ? '英文' : '未設定'}
          </span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF' : row.value === false ? '#FF0000' : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用' : row.value === false ? '關閉' : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm btn-sm' onClick={this.deleteData.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <Container className='mb-5'>
        <Helmet>
          <title>
            常見問題管理
          </title>
        </Helmet>

        <h1 className='page-header'>常見問題管理</h1>
        <Row>
          <Col md='12' className='mb-2 form-inline'>
            <Link to='/app/admin/create_faq' className='btn btn-success mr-4'>
              建立問答
            </Link>
            {/* <SelectControl
              value={this.state.type}
              onChange={(e) => {
                // this.setState({ type: e.target.value })
                this.search({ type: e.target.value })
              }}
            >
              <option value='0'>
                所有類型
              </option>
              <option value='1'>
                帳戶註冊
              </option>
              <option value='2'>
                軟體安裝
              </option>
              <option value='3'>
                訂閱方案
              </option>
              <option value='4'>
                軟體介紹
              </option>
              <option value='5'>
                相機連線
              </option>
              <option value='6'>
                軟體使用教學
              </option>
            </SelectControl> */}
            <SelectControl
              value={this.state.lang}
              onChange={(e) => {
                // this.setState({ lang: e.target.value })
                this.search({ lang: e.target.value })
              }}
            >
              <option value=''>
                所有語系
              </option>
              <option value='tw'>
                繁中
              </option>
              <option value='en'>
                英文
              </option>
            </SelectControl>
          </Col>
          <Col cmd='12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchDataList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12 faq' style={{ padding: '20px' }}>
                    <div dangerouslySetInnerHTML={{ __html: row.original.content }} />
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'type', desc: true }]}
            />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default FAQPage
