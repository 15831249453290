import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import List from 'react-list-select'
import { post, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import './css/list-select.css'

class CreateProductCategoryPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      products: [],
      productsIndex: [],
      userRoleIndex: []
    }
    this.userRoleList = [
      { id: 'client', name: '一般客戶' },
      { id: 'client-gaia', name: '蓋亞客戶' }
    ]
  }

  componentDidMount () {
    this.fetchProductsList()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchProductsList () {
    this.setState({
      loading: true
    })

    get('/1/admin/products?page=1&limit=999&type=2')
      .then(r => {
        if (r.success === true) {
          this.setState({
            loading: false,
            products: r.data
          })
        } else {
          const state = {
            loading: false,
            products: []
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    const products = []
    this.state.products.forEach((product, i) => {
      if (this.state.productsIndex.includes(i)) {
        products.push(product._id)
      }
    })

    const userRoles = []
    this.state.userRoleIndex.forEach(i => {
      userRoles.push(this.userRoleList[i].id)
    })

    this.setState({
      loading: true
    })

    post('/1/admin/product_category', {
      name: this.input.name.value(),
      product: products,
      userRoles: userRoles
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []
    const products = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/products' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    if (this.state.products.length) {
      this.state.products.forEach(pd => {
        products.push(
          <div>
            {pd.name}
          </div>
        )
      })
    }

    return (
      <section className='container'>
        <Helmet>
          <title>建立產品線</title>
        </Helmet>

        <h1 className='page-header'>建立產品線</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='名稱'
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <h5>產品</h5>
              <List
                items={products}
                selected={this.state.productsIndex}
                disabled={[]}
                multiple
                onChange={(selected) => { this.setState({ productsIndex: selected }) }}
              />
              <br /><br />
              <h5>客戶群組</h5>
              <List
                items={this.userRoleList.map(role => role.name)}
                selected={this.state.userRoleIndex}
                disabled={[]}
                multiple
                onChange={(selected) => { this.setState({ userRoleIndex: selected }) }}
              />
              <br /><br />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateProductCategoryPage
