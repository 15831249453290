import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import TextControlFillet from '../../components/text-control-fillet'

class EntryInformationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      chName: this.props.chName ? this.props.chName : '',
      enName: this.props.enName ? this.props.enName : '',
      hasBankAccount: this.props.hasBankAccount ? this.props.hasBankAccount.toString() : '',
      branchBank: this.props.branchBank ? this.props.branchBank : '',
      bankAccount: this.props.bankAccount ? this.props.bankAccount : '',
      custodyAccount: this.props.custodyAccount ? this.props.custodyAccount : '',
      username: this.props.username ? this.props.username : '',
      needParking: this.props.needParking ? this.props.needParking.toString() : '',
      carType: this.props.carType ? this.props.carType : '',
      licensePlateNumber: this.props.licensePlateNumber ? this.props.licensePlateNumber : '',
      needPensionProvisiong: this.props.needPensionProvisiong ? this.props.needPensionProvisiong.toString() : '',
      pensionProvisionProportion: this.props.pensionProvisionProportion ? this.props.pensionProvisionProportion.toString() : '',
      hasHealthReport: this.props.hasHealthReport ? this.props.hasHealthReport.toString() : '',
      emergencyContact: this.props.emergencyContact ? this.props.emergencyContact : '',
      emergencyTelephone: this.props.emergencyTelephone ? this.props.emergencyTelephone : ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      chName: this.state.chName,
      enName: this.state.enName,
      hasBankAccount: this.state.hasBankAccount,
      branchBank: this.state.branchBank,
      bankAccount: this.state.bankAccount,
      custodyAccount: this.state.custodyAccount,
      username: this.state.username,
      needParking: this.state.needParking,
      carType: this.state.carType,
      licensePlateNumber: this.state.licensePlateNumber,
      needPensionProvisiong: this.state.needPensionProvisiong,
      pensionProvisionProportion: this.state.pensionProvisionProportion,
      hasHealthReport: this.state.hasHealthReport,
      emergencyContact: this.state.emergencyContact,
      emergencyTelephone: this.state.emergencyTelephone,
      isComplete2: this.state.chName !== '' && this.state.enName !== '' && this.state.username !== ''
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>2 / 2</p>
                <Progress value='100' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.chName = c)}
                    name='chName'
                    label='中文姓名'
                    value={this.state.chName}
                    onChange={(e) => (this.setState({ chName: e.target.value }))}
                    hasError={this.props.hasError.chName}
                    help={this.props.help.chName}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.enName = c)}
                    name='enName'
                    label='英文姓名'
                    value={this.state.enName}
                    onChange={(e) => (this.setState({ enName: e.target.value }))}
                    hasError={this.props.hasError.enName}
                    help={this.props.help.enName}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.hasBankAccount = c)}
                    name='hasBankAccount'
                    label='是否持有中國信託帳戶'
                    value={this.state.hasBankAccount}
                    onChange={(e) => (this.setState({ hasBankAccount: e.target.value }))}
                    hasError={this.props.hasError.hasBankAccount}
                    help={this.props.help.hasBankAccount}
                    disabled={this.props.loading}
                  >
                    <option value='true'>
                      是
                    </option>
                    <option value='false'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                <Col md='4'>
                  {this.state.hasBankAccount === 'true' &&
                    <TextControl
                      ref={(c) => (this.input.branchBank = c)}
                      name='branchBank'
                      label='分行'
                      value={this.state.branchBank}
                      onChange={(e) => (this.setState({ branchBank: e.target.value }))}
                      hasError={this.props.hasError.branchBank}
                      help={this.props.help.branchBank}
                      disabled={this.props.loading}
                    />}
                </Col>

                <Col md='4'>
                  {this.state.hasBankAccount === 'true' &&
                    <TextControl
                      ref={(c) => (this.input.bankAccount = c)}
                      name='bankAccount'
                      label='帳號'
                      value={this.state.bankAccount}
                      onChange={(e) => (this.setState({ bankAccount: e.target.value }))}
                      hasError={this.props.hasError.bankAccount}
                      help={this.props.help.bankAccount}
                      disabled={this.props.loading}
                    />}
                </Col>

                {this.state.hasBankAccount === 'false' && this.props.workPlace === '新竹' &&
                  <Col md='12'>
                    <ul className='pl-4' style={{ listStyleType: 'disc' }}>
                      <li>
                        5 日(前)報到者，可於報到日後由 HR 通知銀行專人為您開戶服務
                      </li>
                      <li>
                        16 日(後)報到者，請於鄰近中國信託分行辦理開戶，並主動知 開立「酷博樂員工薪資戶」，公司統編：55860209
                      </li>
                    </ul>
                  </Col>}

                {this.state.hasBankAccount === 'false' && this.props.workPlace === '台北' &&
                  <Col md='12'>
                    <p>請於鄰近中國信託分行辦理開戶，並主動知 開立「酷博樂員工薪資戶」，公司統編：55860209</p>
                  </Col>}

                <Col md='12'>
                  <p>
                    備註：當月薪資，於當月 30 或 31 日匯入
                  </p>
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.custodyAccount = c)}
                    name='custodyAccount'
                    label='證券戶集保帳號'
                    value={this.state.custodyAccount}
                    onChange={(e) => (this.setState({ custodyAccount: e.target.value }))}
                    hasError={this.props.hasError.custodyAccount}
                    help={this.props.help.custodyAccount}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <p className='mb-1'>Email帳號</p>
                  <TextControlFillet
                    ref={(c) => (this.input.username = c)}
                    groupClasses={{ 'form-group-inline mr-1 mb-1': true }}
                    name='username'
                    label=''
                    value={this.state.username}
                    onChange={(e) => (this.setState({ username: e.target.value }))}
                    hasError={this.props.hasError.username}
                    disabled={this.props.loading}
                  />@aspeedtech.com
                  <p>
                    自訂5-15個英文字母，前為名後加底線接姓。（例如：jessica_wang@aspeedtech.com）
                  </p>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.emergencyContact = c)}
                    name='emergencyContact'
                    label='緊急聯絡人'
                    value={this.state.emergencyContact}
                    onChange={(e) => (this.setState({ emergencyContact: e.target.value }))}
                    hasError={this.props.hasError.emergencyContact}
                    help={this.props.help.emergencyContact}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.emergencyTelephone = c)}
                    name='emergencyTelephone'
                    label='緊急連絡人電話'
                    value={this.state.emergencyTelephone}
                    onChange={(e) => (this.setState({ emergencyTelephone: e.target.value }))}
                    hasError={this.props.hasError.emergencyTelephone}
                    help={this.props.help.emergencyTelephone}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            {this.props.workPlace === '新竹' &&
              <Card body className='mt-4'>
                <Row className='justify-content-md-center'>
                  <Col md='12'>
                    <h3 className='text-center mt-4'>新竹同仁車位承租需求</h3>
                  </Col>
                  <Col md='4'>
                    <SelectControl
                      ref={(c) => (this.input.needParking = c)}
                      name='needParking'
                      label='車位承租需求'
                      value={this.state.needParking}
                      onChange={(e) => (this.setState({ needParking: e.target.value }))}
                      hasError={this.props.hasError.needParking}
                      help={this.props.help.needParking}
                      disabled={this.props.loading}
                    >
                      <option value='true'>
                        是
                      </option>
                      <option value='false'>
                        否
                      </option>
                    </SelectControl>
                  </Col>
                  <Col md='4'>
                    {this.state.needParking === 'true' &&

                      <SelectControl
                        ref={(c) => (this.input.carType = c)}
                        name='carType'
                        label='機車 / 汽車 (二擇一，汽車下半年度已額滿)'
                        value={this.state.carType}
                        onChange={(e) => (this.setState({ carType: e.target.value }))}
                        hasError={this.props.hasError.carType}
                        help={this.props.help.carType}
                        disabled={this.props.loading}
                      >
                        <option value='機車'>
                          機車
                        </option>
                        <option value='汽車'>
                          汽車
                        </option>
                      </SelectControl>}
                  </Col>
                  <Col md='4'>
                    {this.state.needParking === 'true' &&

                      <TextControl
                        ref={(c) => (this.input.licensePlateNumber = c)}
                        name='licensePlateNumber'
                        label='車牌號碼'
                        value={this.state.licensePlateNumber}
                        onChange={(e) => (this.setState({ licensePlateNumber: e.target.value }))}
                        hasError={this.props.hasError.licensePlateNumber}
                        help={this.props.help.licensePlateNumber}
                        disabled={this.props.loading}
                      />}
                  </Col>
                  <Col md='12'>
                    <p>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://s3-ap-northeast-1.amazonaws.com/admin.aspeedtech.com/share/%E4%BF%A1%E9%A9%8A%E7%A7%91%E6%8A%80%E5%81%9C%E8%BB%8A%E4%BD%8D%E5%87%BA%E7%A7%9F%E8%AA%AA%E6%98%8E-%E6%96%B0%E7%AB%B9%E5%8F%B0%E8%82%A5%E5%A4%A7%E6%A8%93.pdf'
                      >
                        車位出租說明
                      </a>
                      (每輛車可提供 2 台車牌號碼)
                    </p>
                  </Col>
                </Row>
              </Card>}

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <h3 className='text-center mt-4'>勞工退休金提撥</h3>
                </Col>
                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.needPensionProvisiong = c)}
                    name='needPensionProvisiong'
                    label='是否需要'
                    value={this.state.needPensionProvisiong}
                    onChange={(e) => (this.setState({ needPensionProvisiong: e.target.value }))}
                    hasError={this.props.hasError.needPensionProvisiong}
                    help={this.props.help.needPensionProvisiong}
                    disabled={this.props.loading}
                  >
                    <option value='true'>
                      是
                    </option>
                    <option value='false'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                {this.state.needPensionProvisiong === 'true' &&
                  <Col md='12' className='mt-3'>
                    依據《勞工退休金條例》，勞工每個月可以自願提繳 1%～6% 不等的薪資到「勞工退休金個人專戶」。
                    <span className='text-danger'>本人同意每月自願提繳投保金額的
                      <TextControlFillet
                        ref={(c) => (this.input.pensionProvisionProportion = c)}
                        groupClasses={{ 'form-group-inline ml-1 mr-1 mb-0': true }}
                        name='pensionProvisionProportion'
                        label=''
                        value={this.state.pensionProvisionProportion}
                        onChange={(e) => (this.setState({ pensionProvisionProportion: e.target.value }))}
                        hasError={this.props.hasError.pensionProvisionProportion}
                        help={this.props.help.pensionProvisionProportion}
                        disabled={this.props.loading}
                      />
                      % 為勞工退休金，並自當薪資帳戶中扣除。
                    </span>
                  </Col>}
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <h3 className='text-center mt-4'>健檢報告</h3>
                  <h5>一、相關規定</h5>
                  <p>
                    新進員工依據「職業安全衛生法」第20條及「勞工健康保護規則」第10條規定。雇主於僱用勞工時，應施行體格檢查。
                  </p>
                  <h5>二、報到須知</h5>
                  <p>
                    新人於報到日當天繳交”三個月內”體檢報告。<br />
                    或請人力資源部提供健檢資訊，於報到日前自行前往指定之健康檢查醫療機構進行一般體格檢查，並於報到當日繳交
                  </p>
                  <h5>三、付款方式</h5>
                  <p>
                    新人於健檢當天現場付款，再提供收據至人力資源部向公司請款<br />
                    酷博樂統編：55860209
                  </p>
                  <table className='table table-striped table-hover mb-5'>
                    <thead>
                      <tr>
                        <th />
                        <th className='text-center'>新竹</th>
                        <th className='text-center'>台北</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='text-center'>健檢中心</td>
                        <td className='text-center'>新竹安慎診所</td>
                        <td className='text-center'>台北聯安健檢中心</td>
                      </tr>
                      <tr>
                        <td className='text-center'>費用</td>
                        <td className='text-center'>$1,350</td>
                        <td className='text-center'>$2,000</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.hasHealthReport = c)}
                    name='hasHealthReport'
                    label='請選擇'
                    value={this.state.hasHealthReport}
                    onChange={(e) => (this.setState({ hasHealthReport: e.target.value }))}
                    hasError={this.props.hasError.hasHealthReport}
                    help={this.props.help.hasHealthReport}
                    disabled={this.props.loading}
                  >
                    <option value='true'>
                      已持有三個月內健檢資料
                    </option>
                    <option value='false'>
                      請 HR 提供健檢資訊，於報到日前自行前往指定之健康檢查醫療機構進行一般體格檢查，並於報到當日繳交
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 2, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 2, false)}
                    disabled={this.props.loading}
                  >
                    提交
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EntryInformationPage
