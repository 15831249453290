import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import { post, get } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import TextareaControl from '../components/textarea-control'
import SelectControl from '../components/select-control'

class Signup extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    this.fetchProductCategoriesList()
    if (this.input.username) {
      this.input.username.focus()
    }
  }

  fetchProductCategoriesList () {
    this.setState({
      loading: true
    })

    get('/1/product_categories?page=1&limit=999')
      .then(r => {
        if (r.success === true) {
          this.setState({
            loading: false,
            productCategories: r.data
          })
        } else {
          const state = {
            loading: false,
            productCategories: []
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/signup/', {
      first: this.input.first.value(),
      last: this.input.last.value(),
      phone: this.input.phone.value(),
      company: this.input.company.value(),
      username: this.input.username.value(),
      email: this.input.email.value(),
      password: encrypt(this.input.password.value()),
      reqProdCategory: this.input.productCategory.value(),
      remarks: this.input.remarks.value(),
      hasEncrypt: 'true'
    }).then(
      r => {
        if (r.success === true) {
          this.props.loginSuccess(r.data)
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    let alert = []

    if (this.state.success) {
      alert = (
        <Alert
          type='success'
          message={t('send_verification_link')}
        />
      )
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    } else if (this.props.role === 'account') {
      return (<Redirect to='/app/account' />)
    } else if (this.props.role === 'admin') {
      return (<Redirect to='/app/admin' />)
    }

    const productCategory = []
    if (Array.isArray(this.state.productCategories)) {
      this.state.productCategories.forEach(pc => {
        productCategory.push(
          <option value={pc._id} key={pc._id}>
            {pc.name}
          </option>
        )
      })
    }

    let oneName = (
      <TextControl
        ref={(c) => (this.input.first = c)}
        name='first'
        label={t('first_name') + '*'}
        hasError={this.state.hasError.first}
        help={this.state.help.first}
        disabled={this.state.loading}
      />
    )

    let twoName = (
      <TextControl
        ref={(c) => (this.input.last = c)}
        name='last'
        label={t('last_name') + '*'}
        hasError={this.state.hasError.last}
        help={this.state.help.last}
        disabled={this.state.loading}
      />
    )

    if (localStorage.getItem('lang') === 'zh') {
      oneName = (
        <TextControl
          ref={(c) => (this.input.last = c)}
          name='last'
          label={t('last_name') + '*'}
          hasError={this.state.hasError.last}
          help={this.state.help.last}
          disabled={this.state.loading}
        />
      )

      twoName = (
        <TextControl
          ref={(c) => (this.input.first = c)}
          name='first'
          label={t('first_name') + '*'}
          hasError={this.state.hasError.first}
          help={this.state.help.first}
          disabled={this.state.loading}
        />
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{t('sign_up')}</title>
        </Helmet>
        <div className='row'>
          <div className='col-sm-6'>
            <section>
              <h1 className='page-header'>{t('sign_up')}</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                {!this.state.success &&
                  <span>
                    <div className='row'>
                      <div className='col-sm-6'>
                        {oneName}
                      </div>
                      <div className='col-sm-6'>
                        {twoName}
                      </div>
                    </div>
                    <TextControl
                      ref={(c) => (this.input.phone = c)}
                      name='phone'
                      label={t('phone') + '*'}
                      hasError={this.state.hasError.phone}
                      help={this.state.help.phone}
                      disabled={this.state.loading}
                    />
                    <TextControl
                      ref={(c) => (this.input.company = c)}
                      name='company'
                      label={t('company')}
                      hasError={this.state.hasError.company}
                      help={this.state.help.company}
                      disabled={this.state.loading}
                    />
                    <TextControl
                      ref={(c) => (this.input.username = c)}
                      name='username'
                      label={t('username') + '*'}
                      hasError={this.state.hasError.username}
                      help={this.state.help.username}
                      disabled={this.state.loading}
                    />
                    <TextControl
                      ref={(c) => (this.input.email = c)}
                      name='email'
                      label='Email*'
                      hasError={this.state.hasError.email}
                      help={this.state.help.email}
                      disabled={this.state.loading}
                    />
                    <TextControl
                      ref={(c) => (this.input.password = c)}
                      name='password'
                      label={t('password') + '*'}
                      type='password'
                      hasError={this.state.hasError.password}
                      help={this.state.help.password}
                      disabled={this.state.loading}
                    />
                    <SelectControl
                      ref={(c) => (this.input.productCategory = c)}
                      name='productCategory'
                      label={t('product_line')}
                      value={this.state.productCategory}
                      hasError={this.state.hasError.productCategory}
                      help={this.state.help.productCategory}
                      disabled={this.state.loading}
                    >
                      {productCategory}
                    </SelectControl>
                    <TextareaControl
                      ref={(c) => (this.input.remarks = c)}
                      name='remarks'
                      label={t('requirement')}
                      rows='5'
                      value={this.state.remarks}
                      hasError={this.state.hasError.remarks}
                      help={this.state.help.remarks}
                      disabled={this.state.loading}
                    />
                    <ControlGroup hideLabel hideHelp>
                      <Button
                        type='submit'
                        inputClasses={{ 'btn-success': true }}
                        disabled={this.state.loading}
                      >
                        {t('create_account')}
                        <Spinner space='left' show={this.state.loading} />
                      </Button>
                    </ControlGroup>
                  </span>}
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role,
  lang: state.index.lang
})

const mapDispatchToProps = dispatch => ({
  loginSuccess (user) {
    dispatch(loginSuccess(user))
  },
  loginError () {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
