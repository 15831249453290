import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'

class CreateProductPage extends Component {
  constructor () {
    super()
    this.input = {
      subordinate: []
    }
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/product', {
      no: this.input.no.value(),
      name: this.input.name.value(),
      emailDomain: this.input.emailDomain.value(),
      principalMail: this.input.principalMail.value(),
      display: this.input.display.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/products' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>建立產品</title>
        </Helmet>

        <h1 className='page-header'>建立產品</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.no = c)}
                name='no'
                label='編號'
                hasError={this.state.hasError.no}
                help={this.state.help.no}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='名稱'
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.emailDomain = c)}
                name='emailDomain'
                label='綁定域名(有填寫時，用戶須為此域名才能瀏覽及下載檔案)'
                placeholder='email @之後的內容，例如: gmail.com'
                hasError={this.state.hasError.emailDomain}
                help={this.state.help.emailDomain}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.principalMail = c)}
                name='principalMail'
                label='相關負責人 email (請用 ; 分隔)'
                hasError={this.state.hasError.principalMail}
                help={this.state.help.principalMail}
                disabled={this.state.loading}
              />
              <SelectControl
                ref={(c) => (this.input.display = c)}
                name='display'
                label='是否開放客戶申請'
                hasError={this.state.hasError.display}
                help={this.state.help.display}
                disabled={this.state.loading}
              >
                <option value='true'>
                  開放
                </option>
                <option value='false'>
                  關閉
                </option>
              </SelectControl>
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateProductPage
