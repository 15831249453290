import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocDownlaod from './doc-download'
import Personnels from './personnels'

class HomePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
  }

  render () {
    const { t } = this.props
    return (
      <div>
        {['client', 'client-gaia'].includes(this.props.userRole) &&
          <DocDownlaod
            t={t}
          />}
        {this.props.userRole === 'hunter' &&
          <Personnels
            t={t}
          />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userRole: state.index.userRole
})

export default connect(mapStateToProps, null)(HomePage)
