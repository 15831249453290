import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText, Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormText
} from 'reactstrap'
import moment from 'moment'
import { put, get, postFormData, putFormData, deleteItem } from '../utils/httpAgent'
import Alert from '../shared/alert'
// import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'
import FileControl from '../components/file-control'
import DateTimeControl from '../components/datetime-control'
import CheckboxControl from '../components/checkbox-control'
import config from '../config'

class EditDriverPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.fileInput = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      files: [],
      fileIndex: [],
      showModal: false,
      fileId: '',
      description: '',
      version: '',
      file: '',
      date: '',
      productCategory: '',
      fileActive: '',
      productCategoryData: [],
      showNotification: false,
      needNotification: false
    }
    this.toggle = this.toggle.bind(this)
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.handleDeleteFile = this.handleDeleteFile.bind(this)
  }

  componentDidMount () {
    this.fetchData()
    this.fetchProductCategory()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchData () {
    get(`/1/driver/${this.props.match.params.id}`)
      .then(r => {
        if (r.success) {
          this.setState({
            name: r.data.name,
            files: r.data.files,
            productCategory: r.data.productCategory,
            isActive: r.data.isActive.toString()
          })
        }
      })
  }

  fetchProductCategory () {
    get('/1/admin/product_categories?limit=99')
      .then(r => {
        if (r.success) {
          this.setState({
            productCategoryData: r.data
          })
        }
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/driver/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      productCategory: this.input.productCategory.value(),
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false,
            needNotification: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  toggle (fileId = '', description = '', version = '', file = '', date = '', fileActive = '') {
    this.setState({
      showModal: !this.state.showModal,
      fileId: fileId,
      description: description,
      version: version,
      file: file,
      date: date ? new Date(date) : new Date(),
      showNotification: !fileId && true,
      needNotification: false,
      fileActive: fileActive,
      hasError: {},
      help: {}
    })
  }

  handleUploadFile () {
    this.setState({
      loading: true
    })

    postFormData(`/1/admin/driver/${this.props.match.params.id}/file`, {
      description: this.input.description.value(),
      version: this.input.version.value(),
      file: this.input.file.value() ? this.input.file.value() : '',
      needNotification: this.state.needNotification,
      date: this.state.date.getTime()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            loading: false,
            files: r.data.files,
            showModal: false,
            hasError: {},
            help: {}
          })
        } else {
          const state = {
            loading: false,
            showModal: true,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          this.setState(state)
        }
      }
    )
  }

  handleUploadFileForUpdate () {
    this.setState({
      loading: true
    })

    putFormData(`/1/admin/driver/${this.props.match.params.id}/file/${this.state.fileId}`, {
      description: this.input.description.value(),
      version: this.input.version.value(),
      file: this.input.file.value() ? this.input.file.value() : '',
      date: this.state.date.getTime(),
      needNotification: this.state.needNotification,
      isActive: this.input.fileActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            loading: false,
            files: r.data.files,
            showModal: false,
            hasError: {},
            help: {}
          })
        } else {
          const state = {
            loading: false,
            files: r.data.files,
            showModal: true,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          this.setState(state)
        }
      }
    )
  }

  handleDeleteFile () {
    if (!window.confirm('是否確定要刪除此檔案？')) {
      return -1
    }

    deleteItem(`/1/admin/driver/${this.props.match.params.id}/file/${this.state.fileId}`)
      .then(r => {
        if (r.success === true) {
          this.setState({
            files: r.data.files,
            showModal: false
          })
        }
      })
  }

  render () {
    let alert = []
    const files = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/drivers' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    if (this.state.files.length) {
      this.state.files.forEach(fi => {
        files.push(
          <ListGroupItem key={fi._id}>
            <ListGroupItemHeading>
              {fi.description}
              {!fi.isActive &&
                <span>(已停用)</span>}
              &nbsp;&nbsp;
              <Button
                outline
                type='button'
                color='info'
                size='sm'
                onClick={e => this.toggle(fi._id, fi.description, fi.version, fi.file, fi.date, fi.isActive)}
              >
                <i className='lnr lnr-pencil' />
              </Button>
            </ListGroupItemHeading>
            <ListGroupItemText>
              版本：{fi.version}&nbsp;&nbsp;更新時間：{moment(fi.date).format('YYYY/MM/DD')}
              &nbsp;&nbsp;
              {fi.file &&
                <a href={`${config.awsUrl}${fi.file}`} target='_blank' rel='noopener noreferrer'>
                  當前檔案
                </a>}
            </ListGroupItemText>
          </ListGroupItem>
        )
      })
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{this.state.name} 驅動程式類別</title>
        </Helmet>

        <div className='row'>
          <div className='col-md-12'>
            <section>
              <h1 className='page-header'>編輯 {this.state.name} 驅動程式類別</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                <TextControl
                  ref={(c) => (this.input.name = c)}
                  name='name'
                  label='名稱'
                  value={this.state.name}
                  onChange={(e) => (this.setState({ name: e.target.value }))}
                  hasError={this.state.hasError.name}
                  help={this.state.help.name}
                  disabled={this.state.loading}
                />

                <Button color='primary' onClick={e => this.toggle()}>新增檔案</Button>
                <Modal isOpen={this.state.showModal} toggle={e => this.toggle()}>
                  <ModalHeader toggle={e => this.toggle()}>{!this.state.fileId ? '新增檔案' : '更新檔案'}</ModalHeader>
                  <ModalBody>
                    <Form>
                      <TextControl
                        ref={(c) => (this.input.description = c)}
                        name='description'
                        label='名稱'
                        value={this.state.description}
                        onChange={(e) => (this.setState({ description: e.target.value }))}
                        hasError={this.state.hasError.description}
                        help={this.state.help.description}
                        disabled={this.state.loading}
                        placeholder='請輸入檔案標題或簡介'
                      />
                      <TextControl
                        ref={(c) => (this.input.version = c)}
                        name='version'
                        label='版本'
                        value={this.state.version}
                        onChange={(e) => (this.setState({ version: e.target.value }))}
                        hasError={this.state.hasError.version}
                        help={this.state.help.version}
                        disabled={this.state.loading}
                        placeholder='請輸入版本號'
                      />
                      <FormGroup>
                        <FileControl
                          ref={(c) => (this.input.file = c)}
                          name='file'
                          label='檔案'
                          onChange={(e) => (this.setState({ showNotification: e.target.value && true }))}
                          hasError={this.state.hasError.file}
                          help={this.state.help.file}
                          disabled={this.state.loading}
                        />
                        {this.state.file &&
                          <a href={`${config.awsUrl}${this.state.file}`} target='_blank' rel='noopener noreferrer'>
                            當前檔案
                          </a>}
                        <FormText color='muted'>
                          此欄位若未填入，下載頁面將顯示為「 The utility has been moved to Document Download Page for Cupola360 registered developers to access.」。
                          若檔名相同，將會覆蓋到其他檔案。
                        </FormText>
                      </FormGroup>

                      {this.state.showNotification &&
                        <CheckboxControl
                          ref={(c) => (this.input.needNotification = c)}
                          inputClasses={{ 'mb-4': true }}
                          name='needNotification'
                          label='是否寄發檔案更新通知信'
                          checked={this.state.needNotification}
                          onChange={(e) => (this.setState({ needNotification: !this.state.needNotification }))}
                          disabled={this.state.loading}
                        />}

                      <DateTimeControl
                        ref={(c) => (this.input.date = c)}
                        name='date'
                        label='更新日期'
                        placeholder='檔案更新日期'
                        timeFormat={false}
                        value={this.state.date}
                        onChange={(e) => this.setState({ date: new Date(e.valueOf()) })}
                        hasError={this.state.hasError.date}
                        help={this.state.help.date}
                        disabled={this.state.loading}
                      />

                      {this.state.fileActive !== '' &&
                        <SelectControl
                          ref={(c) => (this.input.fileActive = c)}
                          name='fileActive'
                          label='是否啟用'
                          value={this.state.fileActive}
                          onChange={(e) => (this.setState({ fileActive: e.target.value }))}
                          hasError={this.state.hasError.fileActive}
                          help={this.state.help.fileActive}
                          disabled={this.state.loading}
                        >
                          <option value='true'>
                            啟用
                          </option>
                          <option value='false'>
                            關閉
                          </option>
                        </SelectControl>}
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    {this.state.fileId &&
                      <Button
                        color='danger'
                        className='mr-4'
                        onClick={this.handleDeleteFile}
                      >
                        刪除
                      </Button>}
                    {this.state.fileId &&
                      <Button color='success' onClick={e => this.handleUploadFileForUpdate()}>更新</Button>}
                    {!this.state.fileId &&
                      <Button color='primary' onClick={e => this.handleUploadFile()}>新增</Button>}
                    {' '}
                    <Button color='secondary' onClick={e => this.toggle()}>取消</Button>
                  </ModalFooter>
                </Modal>

                <ListGroup className='mt-3 mb-3'>
                  {files}
                </ListGroup>

                <SelectControl
                  ref={(c) => (this.input.productCategory = c)}
                  name='productCategory'
                  label='產品線'
                  value={this.state.productCategory}
                  onChange={(e) => this.setState({ productCategory: e.target.value })}
                  hasError={this.state.hasError.productCategory}
                  help={this.state.help.productCategory}
                  disabled={this.state.loading}
                >
                  {this.state.productCategoryData.map(pc => (
                    <option value={pc._id} key={pc._id}>
                      {pc.name}
                    </option>
                  ))}
                </SelectControl>

                <SelectControl
                  ref={(c) => (this.input.isActive = c)}
                  name='isActive'
                  label='是否啟用'
                  value={this.state.isActive}
                  onChange={(e) => (this.setState({ isActive: e.target.value }))}
                  hasError={this.state.hasError.isActive}
                  help={this.state.help.isActive}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    啟用
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    color='success'
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default EditDriverPage
