import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import CheckboxControl from '../../components/checkbox-control'

class EmployeeInfoSecurityPolicyPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      name: this.props.name ? this.props.name : '', // 立同意書人
      idNumber: this.props.idNumber ? this.props.idNumber : '', // 身分證字號
      residenceAddress: this.props.residenceAddress ? this.props.residenceAddress : '', // 戶籍地址
      isComplete: this.props.isComplete
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 5, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setPersonnel({
      name: this.state.name,
      idNumber: this.state.idNumber,
      residenceAddress: this.state.residenceAddress,
      isComplete: !!this.state.name && !!this.state.idNumber && !!this.state.residenceAddress
    }, 5, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>5 / 6</p>
                <Progress value='90' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>

            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12' className='mb-5'>
                  <h6>1 目的</h6>
                  <p>為確保酷博樂股份有限公司（以下簡稱本公司）所屬之資訊資產的機密性、完整性、可用性及適法性，以符合相關法令、法規與合約之要求，使其免於遭受內、外部蓄意或意外之威脅，並衡酌本公司之業務需求，訂定本政策。</p>

                  <h6>2 範圍</h6>
                  <ul className='list-unstyled pl-3'>
                    <li>2.1 本政策適用對象包括本公司所有同仁及有權限進入本公司之外部人員。</li>
                    <li>2.2 資訊安全管理之範疇涵括 14 個領域，避免因人為疏失、蓄意或天然災害等因素，對本公司造成各種可能之風險及危害，各領域分述如下：
                      <ul className='list-unstyled pl-3'>
                        <li>2.2.1 資訊安全政策。</li>
                        <li>2.2.2 資訊安全之組織。</li>
                        <li>2.2.3 人力資源安全。</li>
                        <li>2.2.4 資產管理。</li>
                        <li>2.2.5 存取控制。</li>
                        <li>2.2.6 密碼學。</li>
                        <li>2.2.7 實體及環境安全。</li>
                        <li>2.2.8 運作安全。</li>
                        <li>2.2.9 通訊安全。</li>
                        <li>2.2.10 系統獲取、開發及維護。</li>
                        <li>2.2.11 供應者關係。</li>
                        <li>2.2.12 資訊安全事故管理。</li>
                        <li>2.2.13 營運持續管理之資訊安全層面。</li>
                        <li>2.2.14 遵循性。</li>
                      </ul>
                    </li>
                  </ul>

                  <h6>3 目標</h6>
                  <p>為維護本公司資訊資產之機密性、完整性、可用性與適法性，並保障客戶資料之安全，期藉由本公司全體同仁共同努力以達成下列資訊安全管理目標：</p>
                  <ul className='list-unstyled pl-3'>
                    <li>3.1 保護本公司業務服務之安全，確保資訊需經授權人員才可存取資訊，以確保其機密性。</li>
                    <li>3.2 保護本公司業務服務之安全，避免未經授權的修改，以確保其正確性與完整性。</li>
                    <li>3.3 建立本公司業務永續運作計畫，以確保本公司業務服務持續運作之可用性。</li>
                    <li>3.4 確保本公司各項業務服務之執行符合相關法令、法規或合約之要求。</li>
                    <li>3.5 為評量資訊安全管理目標達成情形，特訂定資訊安全管理指標如下：</li>
                    <li>3.5.1 定量化指標
                      <ul className='list-unstyled pl-3'>
                        <li>(1) 為確保資訊需經權責單位授權才可存取，以確保其機密性，每年發生管制機密（含）以上等級資訊外洩之事件為 0 件。</li>
                        <li>(2) 為確保本公司客戶產品與資料之正確性與完整性，每年發生資料遭未經授權竄改之事件為0件。</li>
                        <li>(3) 為確保本公司資訊業務服務得以持續運作，演練及維護營運持續計畫每年至少需進行1次。</li>
                        <li>(4) 確保因資訊安全事件所造成系統、主機或網路異常而中斷營運服務之情事，本公司資訊服務可用性要求如下：
                          <ul className='list-unstyled pl-3'>
                            <li>(a) 基礎維運服務達全年上班時間 99% 以上。</li>
                            <li>(b) 關鍵業務服務中斷，每季不得超過 3 次，每次不得超過 4 工作小時。</li>
                            <li>(5) 為確保本公司資訊安全措施或規範符合現行法令、法規與合約之要求，每年至少需執行內部稽核1次。</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>3.5.2 定性化指標
                      <ul className='list-unstyled pl-3'>
                        <li>(1) 應定期審查本公司資訊安全組織人員執掌，以確保資訊安全工作之推展。</li>
                        <li>(2) 應依員工職務及責任提供適當之資訊安全相關訓練。</li>
                        <li>(3) 應加強本公司實體環境安全，採取適當之保護及權限控管機制。</li>
                        <li>(4) 應確保資訊不因傳遞過程或無意間之行為，透漏給未經授權之第三者。</li>
                        <li>(5) 應加強存取控制，防止未經授權之不當存取，以確保本公司資訊資產已受適當之保護。</li>
                        <li>(6) 資訊系統應考量安全需求，應定期監控並進行系統弱點安全管理。</li>
                        <li>(7) 應確保所有資訊安全事件或可疑之安全弱點，均依循適當之通報機制向上反應，並予以適當調查及處理。</li>
                      </ul>
                    </li>
                  </ul>
                  <h6>4 資訊安全管理責任</h6>
                  <ul className='list-unstyled pl-3'>
                    <li>4.1 本公司應成立資訊安全組織，統籌資訊安全政策之推動與管理事項。</li>
                    <li>4.2 本公司各級主管應積極參與資訊安全相關活動，並支持資訊安全業務。</li>
                    <li>4.3 本公司各級主管對本政策及相關資訊安全管理規範負督導執行之責。</li>
                    <li>.4 本公司所有同仁及有權限進入本公司之外部人員，應遵守本政策及相關規範，恪盡保護本公司資訊資產安全之責，並有責任透過適當通報機制，通報資訊安全事件或資訊安全弱點。</li>
                    <li>4.5 任何危及資訊安全之行為，將視情節或疏失輕重，依本公司之相關規定進行議處或追究其法律責任。</li>
                  </ul>
                  <h6>5 資訊安全政策之評估及實施</h6>
                  <ul className='list-unstyled pl-3'>
                    <li>5.1 本政策應每年定期進行評估，以反映本公司資訊安全管理政策、相關法令規範、環境及公司業務之最新狀況，以確保資訊安全實務運作之可行性及有效性。</li>
                    <li>5.2 本政策奉核定後公告施行，修訂時亦同。</li>
                  </ul>
                  <p className='ml-5'>此 致</p>
                  <p className='ml-2'>酷 博 樂 股 份 有 限 公 司</p>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.name = c)}
                    name='name'
                    label='立同意書人'
                    value={this.state.name}
                    onChange={(e) => (this.setState({ name: e.target.value }))}
                    hasError={this.props.hasError.name}
                    help={this.props.help.name}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.idNumber = c)}
                    name='idNumber'
                    label='身份證字號'
                    value={this.state.idNumber}
                    onChange={(e) => (this.setState({ idNumber: e.target.value }))}
                    hasError={this.props.hasError.idNumber}
                    help={this.props.help.idNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.residenceAddress = c)}
                    name='residenceAddress'
                    label='戶籍地址'
                    value={this.state.residenceAddress}
                    onChange={(e) => (this.setState({ residenceAddress: e.target.value }))}
                    hasError={this.props.hasError.residenceAddress}
                    help={this.props.help.residenceAddress}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <CheckboxControl
                    ref={(c) => (this.input.isComplete = c)}
                    inputClasses={{ h6: true }}
                    name='isComplete'
                    label='同意上述規定'
                    checked={this.state.isComplete}
                    onChange={(e) => (this.setState({ isComplete: !this.state.isComplete }))}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 5, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 4, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 6, false)}
                    disabled={this.props.loading || !this.state.isComplete}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EmployeeInfoSecurityPolicyPage
