import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'

class ForgotPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.email) {
      this.input.email.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/login/forgot/', {
      email: this.input.email.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          let state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (let key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    let alert = []

    if (this.props.role === 'account') {
      return (<Redirect to='/app/account' />)
    } else if (this.props.role === 'admin') {
      return (<Redirect to='/app/admin' />)
    }

    if (this.state.success) {
      alert = <div key='success'>
        <Alert
          type='success'
          message={t('reset_success')}
        />
        <Link to='/app/' className='btn btn-link'>{t('back_login')}</Link>
      </div>
    } else if (this.state.error) {
      alert = <Alert
        type='danger'
        message={this.state.error}
      />
    }

    return (
      <section className='container'>
        <Helmet>
          <title>{t('forgot_password')}</title>
        </Helmet>
        <div className='container'>
          <h1 className='page-header'>{t('forgot_password')}</h1>
          <div className='row'>
            <div className='col-sm-6'>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                { !this.state.success &&
                  <span>
                    <TextControl
                      ref={(c) => (this.input.email = c)}
                      name='email'
                      label={t('your_email')}
                      hasError={this.state.hasError.email}
                      help={this.state.help.email}
                      disabled={this.state.loading}
                    />
                    <ControlGroup hideLabel hideHelp>
                      <Button
                        type='submit'
                        inputClasses={{ 'btn-primary': true }}
                        disabled={this.state.loading}>
                        {t('send_reset')}
                        <Spinner space='left' show={this.state.loading} />
                      </Button>
                      <Link to='/app/' className='btn btn-link'>{t('back_login')}</Link>
                    </ControlGroup>
                  </span>
                }
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

export default connect(mapStateToProps, null)(ForgotPage)
