import { useEffect } from 'react'

const ChatwootWidget = ({
  userId = '',
  email = '',
  fullName = '',
  companyName = ''
}) => {
  useEffect(() => {
    (function (d, t) {
      const BASE_URL = 'https://chat.upday.ai'
      const g = d.createElement(t); const s = d.getElementsByTagName(t)[0]
      g.src = BASE_URL + '/packs/js/sdk.js'
      g.defer = true
      g.async = true
      s.parentNode.insertBefore(g, s)
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'pQC5t1sdQKbAGXVJt9ysr8Qm',
          baseUrl: BASE_URL
        })
      }
    })(document, 'script')
  }, [])

  useEffect(() => {
    if (userId && email) {
      setTimeout(
        function () {
          window.$chatwoot.setUser(userId, {
            email: email,
            name: fullName,
            description: '',
            company_name: companyName,
            social_profiles: {
              twitter: '',
              linkedin: '',
              facebook: '',
              github: ''
            }
          })
        }, 2000)
    }
  }, [userId, email])

  return null // This component does not render anything
}

export default ChatwootWidget
