import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, deleteItem } from '../../utils/httpAgent'

class FwVersionPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchData = this.handleFetchData.bind(this)
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/fw_versions?page=${page}&limit=${limit}&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteData (uid, name) {
    if (!window.confirm(`是否確定要刪除韌體版本 ${name}？`)) {
      return -1
    }

    deleteItem(`/1/admin/fw_version/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchData()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/fw_version/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: 'Model',
        accessor: 'model'
      }, {
        Header: 'Manufacturer',
        accessor: 'manufacturer'
      }, {
        Header: 'OemFwVersion',
        accessor: 'oemFwVersion'
      }, {
        Header: 'File',
        accessor: 'file'
      }, {
        Header: '是否發布',
        accessor: 'isPublic',
        width: 80,
        Cell: row => (
          <span>
            {row.value === true
              ? '是'
              : row.value === false
                ? '否'
                : '異常'}
          </span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            {row.value === true
              ? '是'
              : row.value === false
                ? '否'
                : '異常'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '創建日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteData.bind(this, row.value, row.original.name)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            韌體版本管理
          </title>
        </Helmet>

        <h1 className='page-header'>韌體版本管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to='/app/admin/create_fw_version' className='btn btn-success'>
              建立韌體版本
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              keyField='_id'
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              loadingText='資料載入中，請稍候...'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'createTime', desc: true }]}
            />
          </div>
        </div>

      </section>
    )
  }
}

export default FwVersionPage
