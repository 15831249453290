import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import TextControlFillet from '../../components/text-control-fillet'
import DataTimeControlFillet from '../../components/datetime-control-fillet'
import CheckboxControl from '../../components/checkbox-control'

class EmployeeGuaranteePage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      name: this.props.name ? this.props.name : '', // 立同意書人
      employeeDate: this.props.employeeDate ? new Date(this.props.employeeDate) : '',
      idNumber: this.props.idNumber ? this.props.idNumber : '', // 身分證字號
      isComplete: this.props.isComplete
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 4, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setPersonnel({
      name: this.state.name,
      employeeDate: this.state.employeeDate ? this.state.employeeDate.getTime() : '',
      idNumber: this.state.idNumber,
      isComplete: this.state.name && this.state.employeeDate && this.state.idNumber && true
    }, 4, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>4 / 6</p>
                <Progress value='80' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>

            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12' className='mb-5'>
                  本人
                  <TextControlFillet
                    ref={(c) => (this.input.name = c)}
                    groupClasses={{ 'form-group-inline ml-1 mr-1': true }}
                    name='name'
                    label=''
                    value={this.state.name}
                    onChange={(e) => (this.setState({ name: e.target.value }))}
                    hasError={this.props.hasError.name}
                    disabled={this.props.loading}
                  />
                  於
                  <DataTimeControlFillet
                    ref={(c) => (this.input.employeeDate = c)}
                    groupClasses={{ 'form-group-inline ml-1 mr-1': true }}
                    name='employeeDate'
                    timeFormat={false}
                    value={this.state.employeeDate}
                    onChange={(e) => {
                      this.setState({ employeeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.employeeDate}
                    disabled={this.props.loading}
                  />
                  受聘於酷博樂股份有限公司（下稱「酷博樂」）茲此聲明已確實知悉酷博樂對他人智慧財產及權益之尊重，及酷博樂嚴格禁止員工於職務上有不法侵害他人智慧財產權或其他權益之行為。是本人特此保證於受聘期間，絕不為下列行為：
                  <p className='mt-4'>一 、絕不侵害他人智慧財產權。</p>
                  <p>二 、絶不將依法令或契約對第三人（包括但不限於前雇主）負有保密義務之資訊向酷博樂揭露。</p>
                  <p>三 、絕不將未經他人合法授權之智慧財產、機密資訊或營業秘密引用或使用於與職務有關之事項上。</p>
                  <p className='mt-4'>本人同意如有違反前述保證內容，願自負法律責任；如致酷博樂受有損害者，亦同意賠償酷博樂因此所受之損害。</p>
                  <p>此致</p>
                  <p>酷博樂股份有限公司</p>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.name = c)}
                    name='name'
                    label='立同意書人'
                    value={this.state.name}
                    onChange={(e) => (this.setState({ name: e.target.value }))}
                    hasError={this.props.hasError.name}
                    help={this.props.help.name}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.idNumber = c)}
                    name='idNumber'
                    label='身份證字號'
                    value={this.state.idNumber}
                    onChange={(e) => (this.setState({ idNumber: e.target.value }))}
                    hasError={this.props.hasError.idNumber}
                    help={this.props.help.idNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <CheckboxControl
                    ref={(c) => (this.input.isComplete = c)}
                    inputClasses={{ h6: true }}
                    name='isComplete'
                    label='同意上述規定'
                    checked={this.state.isComplete}
                    onChange={(e) => (this.setState({ isComplete: !this.state.isComplete }))}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 4, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 3, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 5, false)}
                    disabled={this.props.loading || !this.state.isComplete}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EmployeeGuaranteePage
