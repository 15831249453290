import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { Col, Row } from 'reactstrap'
import { get, put, deleteItem } from '../../utils/httpAgent'
import SelectControl from '../../components/select-control-lite'
import TextControl from '../../components/text-control-fillet'
import Button from '../../components/button'
import Spinner from '../../components/spinner'

class NdaCompaniesPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0,
      pageSize: 10,
      sorted: [{ id: 'createTime', desc: true }],
      search: ''
    }
    this.handleFetchData = this.handleFetchData.bind(this)
  }

  search () {
    this.handleFetchData({
      page: this.state.page,
      pageSize: this.state.pageSize,
      sorted: this.state.sorted,
      search: this.state.search
    })
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 0
    const pageSize = state ? state.pageSize : 10
    const search = this.state.search || ''
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      page: state.page,
      pageSize,
      sorted: state.sorted,
      loading: true
    })

    get(`/1/admin/nda_companies?page=${page}&limit=${pageSize}&sort=${sorted}&search=${search}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleSetStatus (id, hasNda) {
    put(`/1/admin/nda_company/${id}`, {
      hasNda
    }).then(r => {
      if (r.success === true) {
        this.handleFetchData({
          page: this.state.page,
          pageSize: this.state.pageSize,
          sorted: this.state.sorted
        })
      }
    })
  }

  deleteData (uid, company) {
    if (!window.confirm(`是否確定要刪除 ${company} 公司 NDA 簽署 ？`)) {
      return -1
    }

    deleteItem(`/1/admin/nda_company/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchData({
            page: this.state.page,
            pageSize: this.state.pageSize,
            sorted: this.state.sorted
          })
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/nda_company/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '名稱',
        accessor: 'name'
      }, {
        Header: 'ERP 代號',
        accessor: 'erpNo',
        width: 80
      }, {
        Header: 'email 域名',
        accessor: 'emailDomain'
      }, {
        Header: '已簽署',
        accessor: 'hasNda',
        width: 110,
        Cell: row => (
          <SelectControl
            value={row.value}
            onChange={(e) => (this.handleSetStatus(row.original._id, e.target.value))}
          >
            <option value='true'>
              已簽署
            </option>
            <option value='false'>
              未簽署
            </option>
          </SelectControl>
        )
      }, {
        Header: '最後核可人',
        accessor: 'approver',
        width: 120,
        Cell: row => (
          <span>
            {row.value.name}
          </span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            {row.value === true
              ? '是'
              : row.value === false
                ? '否'
                : '異常'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '創建日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteData.bind(this, row.value, row.original.name)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            NDA 簽署管理
          </title>
        </Helmet>

        <h1 className='page-header'>NDA 簽署管理</h1>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Link to='/app/admin/create_nda_company' className='btn btn-success'>
              建立 NDA 簽署資料
            </Link>
            <TextControl
              groupClasses={{ 'form-group-inline max-width-125 ml-5': true }}
              name='search'
              value={this.state.search}
              placeholder='名稱、ERP 代號、域名'
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <Button
              type='button'
              inputClasses={{ 'btn btn-primary ml-1 mb-1': true }}
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </Col>
          <Col md={12}>
            <ReactTable
              keyField='_id'
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              loadingText='資料載入中，請稍候...'
              className='-striped -highlight'
              defaultPageSize={this.state.pageSize}
              defaultSorted={[{ id: 'createTime', desc: true }]}
            />
          </Col>
        </Row>

      </section>
    )
  }
}

export default NdaCompaniesPage
