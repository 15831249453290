import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import List from 'react-list-select'
import { put, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'

class EditProductCategoryPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      products: [],
      productIndex: [],
      userRoleIndex: [],
      isActive: 'true'
    }
    this.userRoleList = [
      { id: 'client', name: '一般客戶' },
      { id: 'client-gaia', name: '蓋亞客戶' }
    ]
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchData () {
    get(`/1/product_category/${this.props.match.params.id}?type=0`)
      .then(r => {
        this.setState({
          name: r.data.name,
          userRoleIndex: this.userRoleList.map((role, i) => {
            return r.data.userRoles.includes(role.id) ? i : -1
          }).filter(i => i >= 0),
          isActive: r.data.isActive.toString()
        })

        this.fetchProductsList(r.data.product)
      })
  }

  fetchProductsList (selectedProduct) {
    this.setState({
      loading: true
    })

    get('/1/admin/products?page=1&limit=999&type=2')
      .then(r => {
        if (r.success === true) {
          const productIndex = []
          selectedProduct.forEach((product, i) => {
            const pi = r.data.findIndex(p => p._id === product)

            if (pi >= 0) {
              productIndex.push(pi)
            }
          })

          this.setState({
            loading: false,
            products: r.data,
            productIndex: productIndex
          })
        } else {
          const state = {
            loading: false,
            products: []
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    const products = []
    this.state.products.forEach((product, i) => {
      if (this.state.productIndex.includes(i)) {
        products.push(product._id)
      }
    })

    const userRoles = []
    this.state.userRoleIndex.forEach(i => {
      userRoles.push(this.userRoleList[i].id)
    })

    this.setState({
      loading: true
    })

    put(`/1/admin/product_category/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      product: products,
      userRoles: userRoles,
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []
    const products = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/products' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    if (this.state.products.length) {
      this.state.products.forEach(pd => {
        products.push(pd.name)
      })
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{this.state.name} 產品線</title>
        </Helmet>

        <div className='row'>
          <div className='col-md-12'>
            <section>
              <h1 className='page-header'>編輯產品線 {this.state.name}</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                <TextControl
                  ref={(c) => (this.input.name = c)}
                  name='name'
                  label='名稱'
                  value={this.state.name}
                  onChange={(e) => (this.setState({ name: e.target.value }))}
                  hasError={this.state.hasError.name}
                  help={this.state.help.name}
                  disabled={this.state.loading}
                />
                <h5>產品</h5>
                <List
                  items={products}
                  selected={this.state.productIndex}
                  disabled={[]}
                  multiple
                  onChange={(selected) => { this.setState({ productIndex: selected }) }}
                />
                <br /><br />
                <h5>客戶群組</h5>
                <List
                  items={this.userRoleList.map(role => role.name)}
                  selected={this.state.userRoleIndex}
                  disabled={[]}
                  multiple
                  onChange={(selected) => { this.setState({ userRoleIndex: selected }) }}
                />
                <br /><br />
                <SelectControl
                  ref={(c) => (this.input.isActive = c)}
                  name='isActive'
                  label='是否啟用'
                  value={this.state.isActive}
                  onChange={(e) => (this.setState({ isActive: e.target.value }))}
                  hasError={this.state.hasError.isActive}
                  help={this.state.help.isActive}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    啟用
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default EditProductCategoryPage
