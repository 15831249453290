import { Component } from 'react'

class RedirectC1PA08501 extends Component {
  render () {
    window.location.replace('https://cupola360.com/camera_c1pa08501_manual/')
    return null
  }
}

export default RedirectC1PA08501
