import React, { Component } from 'react'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { post } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'

class Login extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      role: '',
      returnUrl: this.getParameterByName('returnUrl'),
      errorCounter: 0,
      type: ''
    }
  }

  componentDidMount () {
    if (this.input.username) {
      this.input.username.focus()
    }
  }

  componentWillUnmount () {
    this.setState = () => false
  }

  getParameterByName (name) {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/login', {
      username: this.input.username.value(),
      password: encrypt(this.input.password.value()),
      hasEncrypt: 'true'
    }).then(
      r => {
        if (r.success === true && r.data) {
          localStorage.setItem('token', r.data.token)
          const jwtPayload = JSON.parse(window.atob(r.data.token.split('.')[1]))
          this.props.loginSuccess(jwtPayload)
          delete r.data.token
          this.setState({
            success: true,
            error: '',
            loading: false,
            role: r.data.role
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {},
            type: r.type ? r.type : ''
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
            state.errorCounter = this.state.errorCounter + 1
          }
          this.setState(state)
          this.props.loginError()
        }
      }
    )
  }

  handleKeyPressn (e) {
    if (e.key === 'Enter') {
      this.setLogin()
    }
  }

  render () {
    const { t } = this.props
    const backendMsg = this.getParameterByName('msg')
    let alerts = []

    if (this.state.success) {
      if (this.state.returnUrl) {
        return (<Redirect to={this.state.returnUrl} />)
      } else if (this.state.role) {
        return (<Redirect to={`/app/${this.state.role}`} />)
      }
    } else if (this.props.role) {
      if (this.state.returnUrl) {
        return (<Redirect to={this.state.returnUrl} />)
      } else {
        return (<Redirect to={`/app/${this.props.role}`} />)
      }
    } else if (this.state.error) {
      if (this.state.errorCounter > 2 && this.state.type !== 'expired') {
        return (<Redirect to='/app/login/forgot' />)
      } else {
        alerts = (
          <Alert
            type='danger'
            message={this.state.error}
          />
        )
      }
    } else if (backendMsg === 'activate_user_error') {
      alerts = (
        <Alert
          type='danger'
          message={t('activate_user_error')}
        />
      )
    } else if (backendMsg === 'client_is_unreviewed') {
      alerts = (
        <Alert
          type='info'
          message={t('client_is_unreviewed')}
        />
      )
    }

    return (
      <div className='container'>
        <Helmet>
          <title>{t('login')}</title>
        </Helmet>
        <h1 className='page-header'>{t('login')}</h1>
        <div className='row'>
          <div className='col-sm-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alerts}
              <TextControl
                ref={(c) => (this.input.username = c)}
                name='username'
                label={t('username')}
                hasError={this.state.hasError.username}
                help={this.state.help.username}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.password = c)}
                name='password'
                label={t('password')}
                type='password'
                hasError={this.state.hasError.password}
                help={this.state.help.password}
                disabled={this.state.loading}
              />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-primary': true }}
                  disabled={this.state.loading}
                >
                  {t('login')}
                  <Spinner space='left' show={this.state.loading} />
                </Button>
                <Link to='/app/login/forgot' className='btn btn-link'>{t('forgot_password')}</Link>
              </ControlGroup>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

const mapDispatchToProps = dispatch => ({
  loginSuccess (user) {
    dispatch(loginSuccess(user))
  },
  loginError () {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
