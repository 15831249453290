import React, { Component } from 'react'
import ClassNames from 'classnames'
import { connect } from 'react-redux'
import { setLanguage } from 'react-multi-lang'
import { Navbar, Container, Button } from 'reactstrap'

const year = new Date().getFullYear()

class AppLayout extends Component {
  constructor () {
    super()
    this.state = {
      navBarOpen: false
    }
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: false })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  setLanguage (lang) {
    localStorage.setItem('lang', lang)
    setLanguage(lang)
  }

  render () {
    return (
      <div className='pt-5'>
        <Navbar color='light' light expand='md' className='fixed-top'>
          <Container>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo.svg' alt='' />
            </a>
          </Container>
        </Navbar>

        {this.props.children}

        <div className='footer'>
          <div className='container'>
            <ul className='links'>
              <li>
                <Button color='link' onClick={this.setLanguage.bind(this, 'zh')} className='p-0'>繁體中文</Button>
              </li>
              <li>
                <Button color='link' onClick={this.setLanguage.bind(this, 'en')} className='p-0'>English</Button>
              </li>
            </ul>
            <span className='copyright float-right'>© {year} Cupola360</span>

            <div className='clearfix' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

export default connect(mapStateToProps, null)(AppLayout)
