import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
  Badge,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Button,
  Card,
  CardHeader
} from 'reactstrap'
import moment from 'moment'
import axios from 'axios'
import { connect } from 'react-redux'
import { get, put } from '../utils/httpAgent'

class DocDownlaodPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      productCategories: [],
      statusText: this.props.t('loading'),
      eulaSignedDate: undefined,
      eulaMoadl: false,
      eulaCheckboxes: {
        scopeOfLicense: false,
        installationAndUse: false,
        reproductionAndDistribution: false,
        limitations: false,
        support: false,
        nonTransferable: false
      },
      signedEula: false
    }
    this.handleDownload = this.handleDownload.bind(this)
  }

  componentDidMount () {
    this.fetchData()
    const params = new URLSearchParams(window.location.search)
    if (params.get('fileid')) {
      this.handleUrlDownload(params.get('fileid'))
    }

    if (this.props.userRole === 'client-gaia') {
      this.getEulaSignedDate()
    }
  }

  fetchData () {
    get('/1/account/product_categories/files')
      .then(r => {
        if (r.data) {
          let productCategories = r.data
          productCategories = productCategories.map(category => {
            category.product = category.product.map((product, pIndex) => {
              const categorySet = new Set()
              product.file.forEach(file => {
                if (file.category) {
                  categorySet.add(file.category)
                }
              })
              if (categorySet.size) {
                categorySet.add('other')
              }

              product.isCategoryOpen = {}
              categorySet.forEach(category => {
                product.isCategoryOpen[category] = false
              })

              product.file = product.file.sort((a, b) => {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              }) // End sort by name
              if (pIndex === 0) {
                product.isOpen = true
              } else {
                product.isOpen = false
              }
              return product
            })
            return category
          })
          this.setState({
            productCategories,
            statusText: ''
          })
        } else {
          let statusText = this.props.t('apply_permission')
          if (r.errors.length) {
            statusText = r.errors[0]
            if (statusText.startsWith('Exception: ')) {
              statusText = statusText.slice(11)
            }
          }
          this.setState({
            statusText: statusText
          })
        }
      })
  }

  handleToggle (productId) {
    const productCategories = this.state.productCategories.map(category => {
      category.product = category.product.map(p => {
        if (p._id === productId) {
          p.isOpen = !p.isOpen
        }
        return p
      })
      return category
    })
    this.setState({ productCategories })
  }


  getEulaSignedDate () {
    get('/1/account/settings/eula')
      .then(r => {
        if (r.data) {
          if (r.data.eulaSignedDate) {
            this.setState({
              eulaSignedDate: r.data.eulaSignedDate
            })
          } else {
            this.setState({
              eulaMoadl: true
            })
          }
        }
      })
  }

  getFilePromise (fileId) {
    const keyObj = {}
    keyObj[fileId] = {
      progress: 0,
      sentSuccess: null,
      hasClick: true
    }
    this.setState(keyObj)
    get(`/1/account/file/${fileId}/promise`)
      .then(r => {
        if (r.data) {
          if (r.data.key || r.data.sentSuccess !== null) {
            const keyObj = {}
            keyObj[fileId] = r.data
            keyObj[fileId].progress = -1
            keyObj[fileId].hasClick = true
            keyObj[fileId].isPdf = r.data.fileName.toLowerCase().endsWith('.pdf')
            this.setState(keyObj)
          }
        }
      })
  }

  handleDownload (fileId) {
    let uri = `/app/1/account/storage/${this.state[fileId].key}/file`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/account/storage/${this.state[fileId].key}/file`
    }

    const fileObj = {}
    fileObj[fileId] = this.state[fileId]
    fileObj[fileId].progress = 15
    this.setState(fileObj)
    axios({
      url: uri,
      onDownloadProgress: function (progressObj) {
        const progress = Math.round((progressObj.loaded / progressObj.total) * 100)
        if (progress > 15) {
          fileObj[fileId].progress = progress
          this.setState(fileObj)
        }
      }.bind(this),
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      fileObj[fileId].progress = 100
      this.setState(fileObj)

      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', this.state[fileId].fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      fileObj[fileId] = false
      this.setState(fileObj)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleUrlDownload (fileId) {
    const keyObj = {}
    keyObj[fileId] = {
      progress: 0,
      sentSuccess: null,
      hasClick: true
    }
    this.setState(keyObj)
    get(`/1/account/file/${fileId}/promise`)
      .then(r => {
        if (r.data) {
          if (r.data.key || r.data.sentSuccess !== null) {
            const fileObj = {}
            fileObj[fileId] = r.data
            fileObj[fileId].hasClick = true
            fileObj[fileId].progress = 15
            this.setState(fileObj)

            let uri = `/app/1/account/storage/${r.data.key}/file`

            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
              uri = `/1/account/storage/${r.data.key}/file`
            }

            axios({
              url: uri,
              onDownloadProgress: function (progressObj) {
                const progress = Math.round((progressObj.loaded / progressObj.total) * 100)
                if (progress > 15) {
                  fileObj[fileId].progress = progress
                  this.setState(fileObj)
                }
              }.bind(this),
              responseType: 'blob',
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }).then(({ data }) => {
              fileObj[fileId].progress = 100
              this.setState(fileObj)

              const downloadUrl = window.URL.createObjectURL(new Blob([data]))
              const link = document.createElement('a')
              link.href = downloadUrl
              link.setAttribute('download', this.state[fileId].fileName)
              document.body.appendChild(link)
              link.click()
              link.remove()
              fileObj[fileId] = false
              this.setState(fileObj)
            }).catch(function (error) {
              console.log(error)
            })
          }
        }
      })
  }

  handleEulaCheckboxChange = (event) => {
    const { name, checked } = event.target
    this.setState(
        (prevState) => ({
          eulaCheckboxes: {
                ...prevState.eulaCheckboxes,
                [name]: checked,
            },
        }),
        this.checksignedEula
    )
  }

  checksignedEula = () => {
    const { eulaCheckboxes } = this.state
    const allChecked = Object.values(eulaCheckboxes).every(Boolean)
    this.setState({ signedEula: allChecked })
  }

  handleEulaSubmit = () => {
    const { signedEula } = this.state
    if (!signedEula) {
      return
    }

    put('/1/account/settings/eula', {
      signedEula
    }).then(r => {
      if (r.success && r.data) {
        this.setState({
          eulaSignedDate: r.data.eulaSignedDate,
          eulaMoadl: false
        })
      }
    })
  }

  render () {
    const { t } = this.props
    let dataElement = <p>{this.state.statusText}</p>

    if (this.state.productCategories.length) {
      dataElement = []
      this.state.productCategories.forEach(category => {
        dataElement.push(<h3 key={`h3-${category._id}`}>{category.name}</h3>)

        if (category.product.length) {
          category.product.forEach(product => {
            dataElement.push(
              <div key={`accordion-${product._id}`} className='accordion'>
                <Card>
                  <CardHeader>
                    <h3 className='mb-0'>
                      <Button
                        color='link'
                        className={`btn-block text-left ${product.isOpen ? '' : 'collapsed'}`}
                        onClick={() => this.handleToggle(product._id)}
                      >
                        {product.name}
                      </Button>
                    </h3>
                  </CardHeader>
                </Card>
              </div>
            )
            if (product.file && product.file.length) {
              const fileRow = []
              const categorySet = new Set()

              product.file.forEach(file => {
                let downloadUrl = []
                let downloadText = []

                if (this.state[file._id] && this.state[file._id].sentSuccess === null && this.state[file._id].progress === -1) {
                  downloadUrl = (
                    <button className='btn btn-link' onClick={e => this.handleDownload(file._id)}>
                      {t('download')}
                    </button>
                  )
                  if (this.state[file._id].isPdf) {
                    downloadText = (
                      <p className='mt-1 mb-0'>
                        ({t('doc_password_txt')})
                      </p>
                    )
                  }
                } else if (this.state[file._id] && this.state[file._id].sentSuccess !== null) {
                  if (this.state[file._id].sentSuccess === true) {
                    downloadUrl = (
                      <p className='mt-1'>
                        {t('sent_beta_req_success')}
                      </p>
                    )
                  } else {
                    downloadUrl = (
                      <p className='mt-1'>
                        {t('sent_beta_req_fail')}
                      </p>
                    )
                  }
                } else if (this.state[file._id]) {
                  downloadUrl = (
                    <div className='d-inline-block w-50'>
                      <Progress animated color='info' value={this.state[file._id].progress}>
                        {this.state[file._id].progress}%
                      </Progress>
                    </div>
                  )
                }
                fileRow.push(
                  <tr key={`tr-${file._id}`} category={file.category}>
                    <th scope='row'>
                      {file.name}<br />
                      <Badge color='info'>{moment(file.updateTime).format('YYYY/MM/DD')}</Badge>
                    </th>
                    <td className='text-center'>{file.version}</td>
                    {!file.url &&
                      <td>
                        <button
                          className='btn btn-outline-secondary mr-4'
                          onClick={this.getFilePromise.bind(this, file._id)}
                          disabled={this.state[file._id] && this.state[file._id].hasClick}
                        >
                          {t('apply')}
                        </button>
                        &nbsp;
                        {downloadUrl}
                        {downloadText}
                      </td>}
                    {file.url &&
                      <td>
                        <a
                          className='btn btn-link'
                          href={file.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {t('link')}
                        </a>
                      </td>}
                  </tr>
                )

                if (file.category) {
                  categorySet.add(file.category)
                }
              }) // End product.file.forEach

              let categoryRow = []
              if (categorySet.size) {
                categorySet.forEach(category => {
                  const fileRowByCategory = fileRow.filter(file => file.props.category === category)
                  categoryRow.push(
                    <div key={`accordion-${product._id}-${category}`} className='accordion'>
                      <Card>
                        <CardHeader>
                          <h3 className='mb-0'>
                            <Button
                              color='link'
                              className={`btn-block text-left ${product.isCategoryOpen[category] ? '' : 'collapsed'}`}
                              onClick={() => {
                                product.isCategoryOpen[category] = !product.isCategoryOpen[category]
                                this.forceUpdate()
                              }}
                            >
                              {category}
                            </Button>
                          </h3>
                        </CardHeader>
                      </Card>
                    </div>
                  )
                  categoryRow.push(
                    <Collapse
                      key={`collapse-${product._id}-${category}`}
                      isOpen={product.isCategoryOpen[category]}
                    >
                      <table
                        key={`table-${product._id}-${category}`}
                        className='table table-striped table-hover mb-5'
                      >
                        <thead>
                          <tr>
                            <th className='text-center'>{t('file_title')}</th>
                            <th className='w-25 text-center'>{t('version')}</th>
                            <th className='w-25' />
                          </tr>
                        </thead>
                        <tbody>
                          {fileRowByCategory}
                        </tbody>
                      </table>
                    </Collapse>
                  )
                })

                const fileRowByEmptyCategory = fileRow.filter(file => !file.props.category)
                if (fileRowByEmptyCategory.length) {
                  categoryRow.push(
                    <div key={`accordion-${product._id}-other`} className='accordion'>
                      <Card>
                        <CardHeader>
                          <h3 className='mb-0'>
                            <Button
                              color='link'
                              className={`btn-block text-left ${product.isCategoryOpen.other ? '' : 'collapsed'}`}
                              onClick={() => {
                                product.isCategoryOpen.other = !product.isCategoryOpen.other
                                this.forceUpdate()
                              }}
                            >
                              {t('other')}
                            </Button>
                          </h3>
                        </CardHeader>
                      </Card>
                    </div>
                  )

                  categoryRow.push(
                    <Collapse
                      key={`collapse-${product._id}-other`}
                      isOpen={product.isCategoryOpen.other}
                    >
                      <table className='table table-striped table-hover mb-5' >
                        <thead>
                          <tr>
                            <th className='text-center'>{t('file_title')}</th>
                            <th className='w-25 text-center'>{t('version')}</th>
                            <th className='w-25' />
                          </tr>
                        </thead>
                        <tbody>
                          {fileRowByEmptyCategory}
                        </tbody>
                      </table>
                    </Collapse>
                  )
                }
              }  else {
                categoryRow = (
                  <table
                    className='table table-striped table-hover mb-5'
                  >
                    <thead>
                      <tr>
                        <th className='text-center'>{t('file_title')}</th>
                        <th className='w-25 text-center'>{t('version')}</th>
                        <th className='w-25' />
                      </tr>
                    </thead>
                    <tbody>
                      {fileRow}
                    </tbody>
                  </table>
                )
              }

              dataElement.push(
                <Collapse
                  key={`table-${product._id}`}
                  isOpen={product.isOpen}
                >
                  <div style={{ width: '97%' }} className='ml-auto'>
                    {categoryRow}
                  </div>
                </Collapse>
              )
            } // End if (product.file && product.file.length)
          }) // End category.product.forEach
        }
      })
    }

    if (this.props.userRole === 'client-gaia' && this.state.eulaSignedDate === undefined) {
      dataElement = <p>{t('loading')}</p>
    } else if (this.props.userRole === 'client-gaia' && this.state.eulaSignedDate === null) {
      dataElement = (
        <div>
          <p>{t('eula_not_signed')}</p>
          <button
            className='btn btn-primary'
            onClick={() => this.setState({ eulaMoadl: true })}
          >
            {t('sign_eula')}
          </button>
        </div>
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{t('file_download')}</title>
        </Helmet>
        <div className='row'>
          <h1 className='page-header'>{t('file_download')}</h1>
          <div className='col-md-12'>
            {dataElement}
          </div>
        </div>
        <Modal
          size='lg'
          isOpen={this.state.eulaMoadl}
          toggle={() => this.setState({ eulaMoadl: false })}
        >
          <ModalHeader toggle={() => this.setState({ eulaMoadl: false })}>
            END USER LICENCE AGREEMENT
          </ModalHeader>
          <ModalBody>
            <p>This End-User License Agreement (this “EULA”) is a legal agreement between you and your company that authorizes you to acquire access to this database (“YOU”) and ASPEED Technology Inc (“ASPEED”), the licensee of SNPS IBIS model, including all HTML files, XML files, Java files, graphics files, animation files, data files, technology, development tools, scripts and programs, both in object code and source code (the “Software”), the deliverables provided pursuant to this EULA, which may include associated media, printed materials, and “online” or electronic documentation.</p>
            <p>By downloading, installing, copying, or otherwise using the Software, YOU agree to be bound by the terms and conditions set forth in this EULA. If YOU do not agree to the terms and conditions set forth in this EULA, then YOU may not download, install, or use the Software.</p>

            <h2>Grant of License</h2>
            <h3>Scope of License</h3>
            <p>Subject to the terms of this EULA, ASPEED hereby grants to YOU a royalty-free, non-exclusive license to possess and to use a copy of the Software solely for the following purpose, for such time until ASPEED terminates this EULA.</p>
            <label>
              <input
                type='checkbox'
                name='scopeOfLicense'
                className='mr-2'
                checked={this.state.eulaCheckboxes.scopeOfLicense}
                onChange={this.handleEulaCheckboxChange}
              />
              YOU can use the Software solely for simulation and design integration of ASPEED’s integrated circuit.
            </label>

            <h3>Installation and Use</h3>
            <label>
              <input
                type='checkbox'
                name='installationAndUse'
                className='mr-2'
                checked={this.state.eulaCheckboxes.installationAndUse}
                onChange={this.handleEulaCheckboxChange}
              />
              YOU can NOT make back-up copies of the Software.
            </label>

            <h3>Reproduction and Distribution</h3>
            <label>
              <input
                type='checkbox'
                name='reproductionAndDistribution'
                className='mr-2'
                checked={this.state.eulaCheckboxes.reproductionAndDistribution}
                onChange={this.handleEulaCheckboxChange}
              />
              YOU can NOT reproduce or distribute the Software.
            </label>

            <h2>Limitations</h2>
            <label>
              <input
                type='checkbox'
                name='limitations'
                className='mr-2'
                checked={this.state.eulaCheckboxes.limitations}
                onChange={this.handleEulaCheckboxChange}
              />
              YOU can NOT reverse engineer, decompile, or disassemble the Software, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding the limitation.
            </label>

            <h2>Support</h2>
            <label>
              <input
                type='checkbox'
                name='support'
                className='mr-2'
                checked={this.state.eulaCheckboxes.support}
                onChange={this.handleEulaCheckboxChange}
              />
              ASPEED and its licensor has NO obligation to provide support services for the Software.
            </label>

            <h2>Duration</h2>
            <p>This EULA is effective until terminated or suspended by ASPEED, with or without cause. In the event this EULA is terminated, YOU must cease use of the Software and destroy all copies of the Software.</p>

            <h2>Non-Transferable</h2>
            <label>
              <input
                type='checkbox'
                name='nonTransferable'
                className='mr-2'
                checked={this.state.eulaCheckboxes.nonTransferable}
                onChange={this.handleEulaCheckboxChange}
              />
              This EULA is NOT assignable or transferable, and any attempt to do so would be void.
            </label>

            <h2>Warranty Disclaimer</h2>
            <p>ASPEED, AND AUTHOR OF THE SOFTWARE, HEREBY EXPRESSLY DISCLAIM ANY WARRANTY FOR THE SOFTWARE. THE SOFTWARE AND ANY RELATED DOCUMENTATION IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. LICENSEE ACCEPTS ANY AND ALL RISK ARISING OUT OF USE OR PERFORMANCE OF THE SOFTWARE.</p>

            <h2>Limitation of Liability</h2>
            <p>ASPEED SHALL NOT BE LIABLE TO LICENSEE, OR ANY OTHER PERSON OR ENTITY CLAIMING THROUGH LICENSEE ANY LOSS OF PROFITS, INCOME, SAVINGS, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE, DIRECT OR INDIRECT DAMAGE, WHETHER ARISING IN CONTRACT, TORT, WARRANTY, OR OTHERWISE. THESE LIMITATIONS SHALL APPLY REGARDLESS OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</p>

            <h2>Entire Agreement</h2>
            <p>This EULA constitutes the entire agreement between ASPEED and YOU and supersedes all prior understandings of ASPEED and YOU, including any prior representation, statement, condition, or warranty with respect to the subject matter of this EULA.</p>

            <h2>Indemnity</h2>
            <p>If YOU violate this EULA, YOU are solely responsible for any damages caused to ASPEED, its licensors, channel partners, related service providers and subcontractors, other users of the products, or any other individual or legal entity. You hereby agree to defend and hold harmless ASPEED, its licensors, channel partners, related service providers and subcontractors from and against any claim, liability, damages and all costs (including attorneys' fees) are indirectly attributable to your fault, and/or result from (A) violation of any provision of this EULA, or (B) your use or misuse of the Software. ASPEED reserves the right to defend, at its own risk and at its own expense, any claim against which YOU agree to indemnify ASPEED. This Section 9 shall survive termination of this EULA.</p>

            <h2>Jurisdiction</h2>
            <p>This EULA shall be governed by and construed according to the laws of Taiwan, Republic of China without giving effect to provisions for choice of law thereunder. For any disputes arising out of or relating to this Agreement, it is mutually agreed that Hsin-Chu District Court shall be the competent court of first instance.</p>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-primary'
              disabled={!this.state.signedEula}
              onClick={this.handleEulaSubmit}
            >
              {t('submit')}
            </button>
            <button className='btn btn-secondary' onClick={() => this.setState({ eulaMoadl: false })}>{t('close')}</button>
          </ModalFooter>
        </Modal>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  userRole: state.index.userRole
})

export default connect(mapStateToProps, null)(DocDownlaodPage)
