import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import axios from 'axios'
import { post } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import FileControl from '../../components/file-control'
import SelectControl from '../../components/select-control'

class CreateFwVersionPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    const file = this.input.file.value()
    const axiosArray = []
    let chunksInfo = {}
    if (this.input.file.value()) {
      const eachSize = 5242880 //  5 * 1024 * 1024 ==> 5MB
      const blockCount = Math.ceil(file.size / eachSize)

      let ext = file.name.split('.')
      ext = ext[ext.length - 1]
      const hash = localStorage.getItem('token').split('.')[2]
      let uploadChunksUrl = '/app/1/admin/fw_version/upload_chunks'
      if (process.env.NODE_ENV === 'development') {
        uploadChunksUrl = '/1/admin/fw_version/upload_chunks'
      }

      for (let i = 0; i < blockCount; i++) {
        const start = i * eachSize
        const end = Math.min(file.size, start + eachSize)

        const configOfReq = {
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data'
          },
          transformRequest: [function (data) {
            return data
          }]
        }

        const form = new FormData()
        form.append('file', file.slice(start, end))
        form.append('name', file.name)
        form.append('total', blockCount)
        form.append('ext', ext)
        form.append('index', i)
        form.append('size', file.size)
        form.append('hash', hash)

        chunksInfo = {
          total: blockCount,
          ext: ext,
          hash: hash
        }

        axiosArray.push(axios.post(uploadChunksUrl, form, configOfReq))
      } // end for
    }

    axios.all(axiosArray).then(() => {
      post('/1/admin/fw_version/merge_chunks', {
        model: this.input.model.value(),
        manufacturer: this.input.manufacturer.value(),
        oemFwVersion: this.input.oemFwVersion.value(),
        isPublic: this.input.isPublic.value(),
        isActive: this.input.isActive.value(),
        ...chunksInfo
      }).then(
        r => {
          if (r.success === true) {
            this.setState({
              success: true,
              error: '',
              loading: false
            })
          } else {
            const state = {
              success: false,
              error: '',
              loading: false,
              hasError: {},
              help: {}
            }
            for (const key in r.errfor) {
              state.hasError[key] = true
              state.help[key] = r.errfor[key]
            }

            if (r.errors[0] !== undefined) {
              state.error = r.errors[0]
            }
            this.setState(state)
          }
        }
      )
    })
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/fw_versions' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>建立韌體版本</title>
        </Helmet>

        <h1 className='page-header'>建立韌體版本</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <p>* 為必填欄位，其他請視需求填寫。</p>
              <TextControl
                ref={(c) => (this.input.model = c)}
                name='model'
                label='Model*'
                hasError={this.state.hasError.model}
                help={this.state.help.model}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.manufacturer = c)}
                name='manufacturer'
                label='Manufacturer'
                hasError={this.state.hasError.manufacturer}
                help={this.state.help.manufacturer}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.oemFwVersion = c)}
                name='oemFwVersion'
                label='OemFwVersion*'
                hasError={this.state.hasError.oemFwVersion}
                help={this.state.help.oemFwVersion}
                disabled={this.state.loading}
              />
              <FileControl
                ref={(c) => (this.input.file = c)}
                name='file'
                label='檔案'
                onChange={(e) => (this.setState({ file: e.target.value }))}
                hasError={this.state.hasError.file}
                help={this.state.help.file}
                disabled={this.state.loading || !!this.state.url}
              />
              <SelectControl
                ref={(c) => (this.input.isPublic = c)}
                name='isPublic'
                label='是否發布*'
                hasError={this.state.hasError.isPublic}
                help={this.state.help.isPublic}
                disabled={this.state.loading}
              >
                <option value='true'>
                  是
                </option>
                <option value='false'>
                  否
                </option>
              </SelectControl>
              <SelectControl
                ref={(c) => (this.input.isActive = c)}
                name='isActive'
                label='是否啟用*'
                hasError={this.state.hasError.isActive}
                help={this.state.help.isActive}
                disabled={this.state.loading}
              >
                <option value='true'>
                  是
                </option>
                <option value='false'>
                  否
                </option>
              </SelectControl>
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateFwVersionPage
