import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import { get } from '../utils/httpAgent'

const News = () => {
  const { id } = useParams()
  const [name, setName] = useState('')
  const [postTime, setPostTime] = useState('')
  const [content, setContent] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [navBarOpen, setNavBarOpen] = useState(true)

  useEffect(() => {
    get(`/1/news/${id}`)
      .then(r => {
        if (r.success === true) {
          setName(r.data.name)
          const postTime = new Date(r.data.postTime)
          setPostTime(`${postTime.getUTCFullYear()}.${postTime.getUTCMonth() + 1}.${postTime.getUTCDate()}`)
          setContent(r.data.content)
        }
      })
  }, [])

  useEffect(() => {
    if (localStorage.getItem('cookies') !== 'true') {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookies', 'true')
    // 隱藏該 DOM
    setIsVisible(false)
  }

  const toggleMenu = () => {
    setNavBarOpen(!navBarOpen)
  }

  return (
    <div className='tw'>
      <Helmet>
        <title>
          {name}
        </title>
      </Helmet>
      <header>
        <nav className='navbar navbar-expand-xl navbar-light fixed-top'>
          <Container fluid>
            <a href='/' id='navLogo' data-ll-status='entered' className='entered'>
              <img alt='Go Home' className='logo product-logo loaded entered' src='/public/images/logo.svg' title='Cupola' data-ll-status='loaded' />
            </a>

            <NavbarToggler
              onClick={toggleMenu}
              className='navbar-toggler'
            />

            <Collapse isOpen={!navBarOpen} navbar>
              <ul className='navbar-nav mr-auto navbar-main'>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/about/' data-ll-status='entered'>
                    關於酷博樂
                  </a>

                </li>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/products/' data-ll-status='entered'>
                    產品介紹
                  </a>

                </li>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/solutions_patrol/' data-ll-status='entered'>
                    解決方案
                  </a>

                  <div className='dropdown-menu'>

                    <a className='dropdown-item' href='/solutions_patrol/'>
                      智慧巡檢
                    </a>

                    <a className='dropdown-item' href='/solutions_city/'>
                      智慧城市
                    </a>

                    <a className='dropdown-item' href='https://studio.cupola360.com/tw/' target='_blank' rel='noopener noreferrer'>
                      視訊會議
                    </a>

                  </div>

                </li>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/showcase/' data-ll-status='entered'>
                    應用案例
                  </a>

                </li>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/faq/' data-ll-status='entered'>
                    技術支援
                  </a>

                  <div className='dropdown-menu'>

                    <a className='dropdown-item' href='https://cupola360.com/app/account' target='_blank' rel='noopener noreferrer'>
                      資源下載
                    </a>

                    <a className='dropdown-item' href='/faq/'>
                      常見問題
                    </a>

                  </div>

                </li>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/sales_channel/' data-ll-status='entered'>
                    銷售通路
                  </a>

                </li>

                <li className='nav-item dropdown active'>
                  <a className='nav-link active entered' href='/news_all/' data-ll-status='entered'>
                    最新消息
                  </a>

                  <div className='dropdown-menu'>

                    <a className='dropdown-item entered exited' href='/news_product/' data-ll-status='entered'>
                      產品新訊
                    </a>

                    <a className='dropdown-item entered exited' href='/news/' data-ll-status='entered'>
                      媒體報導
                    </a>

                    <a className='dropdown-item entered exited' href='/partner_news/' data-ll-status='entered'>
                      合作夥伴新聞
                    </a>

                  </div>

                </li>

                <li className='nav-item dropdown '>
                  <a className='nav-link entered' href='/contact/' data-ll-status='entered'>
                    聯絡我們
                  </a>

                </li>

              </ul>

              {!navBarOpen &&
                <ul className='navbar-nav mr-auto general-hide navbar-lang-items'>
                  <li className='nav-item'>
                    <button
                      id='lang-back'
                      className='nav-link nav-back'
                      onClick={(event) => {
                        document.querySelector('.navbar-lang-items').classList.toggle('general-hide')
                        document.querySelector('.navbar-lang-items').classList.toggle('mobile-show-block')
                        document.querySelector('.navbar-main').classList.toggle('mobile-hide')
                        document.querySelector('#lang-select').classList.toggle('mobile-hide')
                      }}
                    >
                      語言
                    </button>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='/en/news_product/'>
                      English
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='/news_product/'>
                      繁體中文
                    </a>
                  </li>
                </ul>}

              {!navBarOpen &&
                <div className='dropdown language'>
                  <a
                    id='lang-select'
                    className='nav-link dropdown-toggle no-after entered'
                    data-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-ll-status='entered'
                    onClick={(event) => {
                      event.preventDefault()
                      document.querySelector('.navbar-lang-items').classList.toggle('general-hide')
                      document.querySelector('.navbar-lang-items').classList.toggle('mobile-show-block')
                      document.querySelector('.navbar-main').classList.toggle('mobile-hide')
                      document.querySelector('#lang-select').classList.toggle('mobile-hide')
                    }}
                  />
                </div>}

              {navBarOpen &&
                <UncontrolledDropdown inNavbar className='language'>
                  <DropdownToggle
                    nav
                    caret
                    id='lang-select'
                    className='nav-link dropdown-toggle no-after entered'
                  />
                  <DropdownMenu right>
                    <a className='dropdown-item' href='/en/news_product/'>
                      English
                    </a>
                    <a className='dropdown-item' href='/news_product/'>
                      繁體中文
                    </a>
                  </DropdownMenu>
                </UncontrolledDropdown>}
            </Collapse>
          </Container>
        </nav>

        {isVisible &&
          <nav className='navbar fixed-top cookies'>
            <div className='container p-0'>
              <div className='row w-100 m-0'>
                <div className='col-md-12 p-0' data-nosnippet=''>
                  <p>
                    為了增進此網站功能，我們將在您的裝置上傳送 Cookies 功能；當您繼續瀏覽本網站，我們將認定您已經同意使用 Cookies。相關詳細規範說明，請參閱下方的隱私權政策及 Cookie <a href='/privacy_policy/'>政策連結</a>。
                  </p>
                  <button
                    className='float-right'
                    id='cookies-accept'
                    onClick={handleAccept}
                  >
                    同意
                  </button>
                </div>
              </div>
            </div>
          </nav>}

      </header>

      <main role='main'>
        <section id='main' data-ll-status='entered' className='entered exited'>
          <div className='hero'>
            <div className='main-img d-none d-lg-block' style={{ backgroundImage: 'url(/public/images/keyimg-news.webp)' }} />
            <div className='main-img d-lg-none' style={{ backgroundImage: 'url(/public/images/keyimg-news.webp)' }} />
            <div className='content'>
              <h1 />
              <h2 />
            </div>
          </div>
          <div className='sumenu'>
            <a href='/news_product/' data-ll-status='entered' className='entered exited'>
              <p>
                產品新訊
              </p>
            </a>
          </div>
        </section>

        <section id='about' data-ll-status='entered' className='entered exited'>
          <div className='container-fluid mb-5 max-width-1250'>
            <div className='row'>
              <div className='col-md-12'>
                <h1 className='text-left' id='news-title'>
                  {name}
                </h1>
                <div className='text-left' id='news-date'>
                  {postTime}
                </div>
                <div id='news-content' dangerouslySetInnerHTML={{ __html: content }} />
              </div>
              <div className='col-md-12' style={{ marginTop: '30px' }}>
                <div className='news-content-tag'>panoramic camera</div><div className='news-content-tag'>panoramic industrial camera</div><div className='news-content-tag'>industrial 360 camera</div><div className='news-content-tag'>Live 360 camera</div><div className='news-content-tag'>Live 360 streaming</div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <footer>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 col-12'>
              <h1 className='our-product'>關於本網站</h1>
              <ul className='list-unstyled text-small'>

                <li>
                  <a href='/terms_service/' data-ll-status='entered' className='entered exited'>
                    服務條款
                  </a>
                </li>

                <li>
                  <a href='/privacy_policy/' data-ll-status='entered' className='entered exited'>
                    隱私權政策
                  </a>
                </li>

                <li>
                  <a href='/cookie/' data-ll-status='entered' className='entered'>
                    Cookie 政策
                  </a>
                </li>

              </ul>
            </div>
            <div className='col-md-3 col-12'>
              <h1 className='follow'>追蹤我們</h1>
              <ul className='list-unstyled text-small'>

                <li>
                  <a href='https://www.linkedin.com/company/cupola360/' target='_blank' rel='noopener noreferrer' aria-label='Cupola360 linkedin' data-ll-status='entered' className='entered exited'>
                    <img src='/public/images/icon-linkedin.svg' alt='Cupola360 linkedin' className='loaded entered' />
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div className='row white-border'>
            <div className='col-md-12'>
              <p className='copyright'>
                ©{new Date().getFullYear()} 酷博樂股份有限公司 著作權所有
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div
        className='scroll-up-arrow'
        data-original-title=''
        title=''
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <img alt='scroll-up' className='entered loaded' src='/public/images/icon-top.svg' />
      </div>
    </div>
  )
}

export default News
