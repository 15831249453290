import React, { Component } from 'react'
import ClassNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLanguage } from 'react-multi-lang'
import { Navbar, Collapse, Nav, NavItem, NavbarText, NavbarToggler, Container, Button } from 'reactstrap'
import ChatwootWidget from '../components/chatwoot-widget'

const year = new Date().getFullYear()

class Default extends Component {
  constructor () {
    super()
    this.state = {
      navBarOpen: false
    }
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: false })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  setLanguage (lang) {
    localStorage.setItem('lang', lang)
    setLanguage(lang)
  }

  render () {
    const { t } = this.props

    return (
      <div>
        <Navbar color='light' light expand='md' className='fixed-top'>
          <Container>
            <a href='/'>
              <img className='product-logo' src='/media/logo.svg' alt='' />
            </a>
            <NavbarToggler onClick={this.toggleMenu.bind(this)} />
            <Collapse isOpen={!this.state.navBarOpen} navbar>
              <Nav className='mr-auto' navbar>
                <NavItem>
                  <NavLink exact to='/app/account' activeClassName='active' className='nav-link'> {t('home')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/app/account/setting' activeClassName='active' className='nav-link'> {t('setting')}
                  </NavLink>
                </NavItem>
                {this.props.userRole === 'client' &&
                  <NavItem>
                    <NavLink to='/app/account/report' activeClassName='active' className='nav-link'> {t('report_issues')}
                    </NavLink>
                  </NavItem>}
              </Nav>
              <NavbarText>
                <NavLink to='/app/logout'>
                  {t('logout')}
                </NavLink>
              </NavbarText>
            </Collapse>
          </Container>
        </Navbar>

        {this.props.children}

        <div className='footer'>
          <div className='container'>
            <span className='copyright float-right'>©{year} Cupola360 Inc. All rights reserved.</span>
            <ul className='links'>
              <li>
                <Button color='link' onClick={this.setLanguage.bind(this, 'zh')} className='p-0'>繁體中文</Button>
              </li>
              <li>
                <Button color='link' onClick={this.setLanguage.bind(this, 'en')} className='p-0'>English</Button>
              </li>
            </ul>
            <div className='clearfix' />
          </div>
        </div>

        <ChatwootWidget
          userId={this.props.user}
          email={this.props.email}
          fullName={this.props.fullName}
          companyName={this.props.company}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  userRole: state.index.userRole,
  email: state.index.email,
  fullName: state.index.fullName,
  company: state.index.company
})

export default connect(mapStateToProps, null)(Default)
