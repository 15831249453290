import React from 'react'
import { connect } from 'react-redux'
import { loginSuccess, loginError } from './userAction'

export function requireAuthentication (Component) {
  class AuthenticatedComponent extends React.Component {
    componentDidMount () {
      try {
        const token = localStorage.getItem('token')
        if (token) {
          const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
          if (!jwtPayload._id || !jwtPayload.roles.account) {
            this.loginError()
          } else {
            this.loginSuccess(jwtPayload)
          }
        } else {
          this.loginError()
        }
      } catch (_error) {
        this.loginError()
      }
    }

    loginError () {
      this.props.loginError()
      localStorage.removeItem('token')
      if (window.location.pathname === '/' || window.location.pathname === '/app/') {
        window.location.href = '/app/'
      } else {
        if (window.location.search) {
          window.location.href = `/app/?returnUrl=${window.location.pathname}?${window.location.search.substr(1)}`
        } else {
          window.location.href = `/app/?returnUrl=${window.location.pathname}`
        }
      }
    }

    loginSuccess (user) {
      this.props.loginSuccess({
        authenticated: true,
        user: user.username,
        email: user.email,
        fullName: user.fullName,
        company: user.company,
        userRole: user.userRole
      })
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  const mapDispatchToProps = dispatch => ({
    loginSuccess (user) {
      dispatch(loginSuccess(user))
    },
    loginError () {
      dispatch(loginError())
    }
  })

  return connect(null, mapDispatchToProps)(AuthenticatedComponent)
}
