import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { useParams } from 'react-router-dom'
import { get, put } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

const EditNdaCompanyPage = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const [name, setName] = useState('')
  const [emailDomain, setEmailDomain] = useState('')
  const [hasNda, setHasNda] = useState('true')
  const [erpNo, setErpNo] = useState('')
  const [isActive, setIsActive] = useState('true')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`/1/admin/nda_company/${id}`)
        setName(response.data.name)
        setErpNo(response.data.erpNo)
        setEmailDomain(response.data.emailDomain)
        setHasNda(response.data.hasNda)
        setIsActive(response.data.isActive)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    put(`/1/admin/nda_company/${id}`, {
      name,
      erpNo,
      emailDomain,
      hasNda,
      isActive
    }).then((r) => {
      if (r.success === true) {
        setSuccess(true)
        setError('')
        setLoading(false)
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        setError(state.error)
        setHasError(state.hasError)
        setHelp(state.help)
        setLoading(false)
      }
    })
  }

  let alert = []

  if (success) {
    return <Redirect to='/app/admin/nda_companies' />
  } else if (error) {
    alert = <Alert type='danger' message={error} />
  }

  return (
    <section className='section-home container'>
      <Helmet>
        <title>編輯 NDA 簽署資料</title>
      </Helmet>

      <h1 className='page-header'>編輯 NDA 簽署資料</h1>
      <div className='row'>
        <div className='col-md-6'>
          <form onSubmit={handleSubmit}>
            {alert}
            <TextControl
              name='name'
              label='名稱'
              hasError={hasError.name}
              help={help.name}
              disabled={loading}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextControl
              name='erpNo'
              label='ERP 客戶代號'
              hasError={hasError.erpNo}
              help={help.erpNo}
              disabled={loading}
              value={erpNo}
              onChange={(e) => setErpNo(e.target.value)}
            />
            <TextControl
              name='emailDomain'
              label='email 域名'
              placeholder='@之後的內容，例如: gmail.com'
              hasError={hasError.emailDomain}
              help={help.emailDomain}
              disabled={loading}
              value={emailDomain}
              onChange={(e) => setEmailDomain(e.target.value)}
            />
            <SelectControl
              name='hasNda'
              label='已簽署 NDA'
              hasError={hasError.hasNda}
              help={help.hasNda}
              disabled={loading}
              value={hasNda}
              onChange={(e) => setHasNda(e.target.value)}
            >
              <option value='true'>是</option>
              <option value='false'>否</option>
            </SelectControl>
            <SelectControl
              name='isActive'
              label='是否啟用'
              hasError={hasError.isActive}
              help={help.isActive}
              disabled={loading}
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
            >
              <option value='true'>啟用</option>
              <option value='false'>關閉</option>
            </SelectControl>
            <ControlGroup hideLabel hideHelp>
              <Button
                type='submit'
                inputClasses={{ 'btn-success': true }}
                disabled={loading}
              >
                建立
                <Spinner space='left' show={loading} />
              </Button>
            </ControlGroup>
          </form>
        </div>
      </div>
    </section>
  )
}

export default EditNdaCompanyPage
