import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, put } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'
import DateTimeControl from '../components/datetime-control'

class UserForm extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      roles: 'account',
      isActive: 'no',
      userRole: '',
      username: '',
      email: '',
      timeActive: ''
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    get(`/1/admin/user/${this.props.uid}`)
      .then(r => {
        if (r.success === true) {
          this.setState({
            username: r.data.username,
            email: r.data.email,
            roles: r.data.adminRole ? r.data.adminRole : 'account',
            isActive: r.data.isActive,
            userRole: r.data.userRole,
            timeActive: r.data.timeActive ? new Date(r.data.timeActive) : ''
          })
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/user/${this.props.uid}`, {
      roles: this.state.roles,
      isActive: this.input.isActive.value(),
      userRole: this.input.userRole.value(),
      username: this.input.username.value(),
      email: this.input.email.value(),
      timeActive: this.state.timeActive ? this.state.timeActive.getTime() : ''
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {}
        })
        this.props.onUserRoleChange(this.input.userRole.value())
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  render () {
    let alerts = []

    if (this.state.success) {
      alerts = <Alert type='success' message='帳號資料更新成功' />
    } else if (this.state.error) {
      alerts = <Alert type='danger' message={this.state.error} />
    }

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <fieldset>
          <legend>
            帳號資料
          </legend>
          {alerts}
          <TextControl
            ref={(c) => (this.input.username = c)}
            name='username'
            label='帳號'
            value={this.state.username}
            onChange={(e) => (this.setState({ username: e.target.value }))}
            hasError={this.state.hasError.username}
            help={this.state.help.username}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.email = c)}
            name='email'
            label='email'
            value={this.state.email}
            onChange={(e) => (this.setState({ email: e.target.value }))}
            hasError={this.state.hasError.email}
            help={this.state.help.email}
            disabled={this.state.loading}
          />
          {this.props.adminRole === 'root' &&
            <SelectControl
              ref={(c) => (this.input.roles = c)}
              name='roles'
              label='權限'
              value={this.state.roles}
              onChange={(e) => (this.setState({ roles: e.target.value }))}
              hasError={this.state.hasError.roles}
              help={this.state.help.roles}
              disabled={this.state.loading}
            >
              <option value='account'>
                一般
              </option>
              <option value='root'>
                系統管理者
              </option>
              <option value='root-legal'>
                法務
              </option>
              <option value='root-hr'>
                人力資源
              </option>
              <option value='root-info'>
                公開資訊
              </option>
              <option value='root-sales'>
                業務
              </option>
            </SelectControl>}
          <SelectControl
            ref={(c) => (this.input.isActive = c)}
            name='isActive'
            label='是否啟用 (Email 驗證)'
            value={this.state.isActive}
            onChange={(e) => (this.setState({ isActive: e.target.value }))}
            hasError={this.state.hasError.isActive}
            help={this.state.help.isActive}
            disabled={this.state.loading}
          >
            <option value='yes'>
              啟用
            </option>
            <option value='no'>
              關閉
            </option>
          </SelectControl>
          <SelectControl
            ref={(c) => (this.input.userRole = c)}
            name='userRole'
            label='平台角色'
            value={this.state.userRole}
            onChange={(e) => (this.setState({ userRole: e.target.value }))}
            hasError={this.state.hasError.userRole}
            help={this.state.help.userRole}
            disabled={this.state.loading}
          >
            <option value='client'>
              一般客戶 (可申請檔案權限)
            </option>
            <option value='client-gaia'>
              蓋亞客戶
            </option>
            {/* <option value='hunter'>
              獵人頭 (可管理寄出履歷)
            </option>
            <option value='cupola360ota'>
              Cupola360 OTA
            </option> */}
            <option value='false'>
              關閉
            </option>
          </SelectControl>
          {
            this.state.roles === 'account' &&
              <DateTimeControl
                className='form-control mr-auto ml-auto mb-3'
                ref={(c) => (this.input.timeActive = c)}
                name='timeActive'
                label='啟用到期日'
                timeFormat={false}
                value={this.state.timeActive}
                onChange={(e) => this.setState({ timeActive: e.valueOf() ? new Date(e.valueOf()) : '' })}
                hasError={this.state.hasError.timeActive}
                help={this.state.help.timeActive}
                disabled={this.state.loading}
              />
          }
          <ControlGroup hideLabel hideHelp>
            <Button type='submit' inputClasses={{ 'btn-primary': true }} disabled={this.props.loading}>
              更新
              <Spinner space='left' show={this.props.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  adminRole: state.index.adminRole,
  userRole: state.index.userRole
})

export default connect(mapStateToProps, null)(UserForm)
