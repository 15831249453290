import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { Row, Col, FormGroup, Label } from 'reactstrap'
import Select from 'react-select'
import { get, post } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

const CreateCertificatePage = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const [companyList, setCompanyList] = useState([])
  const [userList, setUserList] = useState([])
  const [company, setCompany] = useState('')
  const [users, setUsers] = useState([])
  const [licenseDuration, setLicenseDuration] = useState('')
  const [licenseDurationUnit, setLicenseDurationUnit] = useState('days')
  const [maxServers, setMaxServers] = useState('')
  const [macAddressList, setMacAddressList] = useState('')
  const [totalMaxStreams, setTotalMaxStreams] = useState('')

  useEffect(() => {
    get('/1/admin/nda_companies/list').then((r) => {
      if (r.success) {
        setCompanyList(r.data)
      }
    })

    get('/1/admin/accounts/list').then((r) => {
      if (r.success) {
        setUserList(r.data)
      }
    })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    post('/1/admin/certificate', {
      company,
      users,
      licenseDuration: licenseDuration && licenseDurationUnit ? `${licenseDuration} ${licenseDurationUnit}` : null,
      maxServers,
      macAddressList: macAddressList ? macAddressList.split(',') : null,
      totalMaxStreams
    }).then((r) => {
      if (r.success === true) {
        setSuccess(true)
        setError('')
        setLoading(false)
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        setError(state.error)
        setHasError(state.hasError)
        setHelp(state.help)
        setLoading(false)
      }
    })
  }

  let alert = []

  if (success) {
    return <Redirect to='/app/admin/certificates' />
  } else if (error) {
    alert = <Alert type='danger' message={error} />
  }

  const companyOptions = companyList.map((company) => {
    return {
      value: company._id,
      label: company.name,
      id: company._id,
      name: company.name,
      emailDomain: company.emailDomain
    }
  })

  const usersOptions = userList.map((user) => {
    return {
      value: user.userId,
      label: `${user.name}${user.company ? ` (${user.company})` : ''}`,
      id: user.userId,
      name: user.name,
      username: user.username,
      email: user.email
    }
  })

  return (
    <section className='section-home container'>
      <Helmet>
        <title>建立 Patrol 使用憑證</title>
      </Helmet>

      <h1 className='page-header'>建立 Patrol 使用憑證</h1>
      <Row>
        <Col md={12}>
          {alert}
        </Col>
        <Col md={6}>
          <FormGroup className={hasError.company ? 'has-error' : ''}>
            <Label className='control-label'>
              被授權的公司
            </Label>
            <Select
              className='react-select'
              isClearable
              options={companyOptions}
              value={company}
              onChange={(e) => {
                if (e === null) {
                  setCompany('')
                  return
                }
                setCompany(e)
              }}
              noOptionsMessage={() => '查無該資訊'}
            />
            {help.company &&
              <span className='help-block'>
                {help.company}
              </span>}
          </FormGroup>
          <FormGroup className={hasError.users ? 'has-error' : ''}>
            <Label className='control-label'>
              被授權的使用者 (用於 gmail 用戶)
            </Label>
            <Select
              className='react-select'
              closeMenuOnSelect={false}
              options={usersOptions}
              isMulti
              value={users}
              onChange={(e) => {
                if (e === null) {
                  setUsers([])
                  return
                }
                setUsers(e)
              }}
              noOptionsMessage={() => '查無該資訊'}
            />
            {help.users &&
              <span className='help-block'>
                {help.users}
              </span>}
          </FormGroup>
        </Col>
        <Col md={12} />
        <Col md={4}>
          <TextControl
            name='licenseDuration'
            label='每次授權的時間'
            hasError={hasError.licenseDuration}
            help={help.licenseDuration}
            disabled={loading}
            value={licenseDuration}
            onChange={(e) => setLicenseDuration(e.target.value)}
          />
        </Col>
        <Col md={2}>
          <SelectControl
            name='licenseDurationUnit'
            label='時間單位'
            hasError={hasError.licenseDuration}
            help={help.licenseDuration}
            disabled={loading}
            value={licenseDurationUnit}
            onChange={(e) => setLicenseDurationUnit(e.target.value)}
          >
            <option value='days'>天</option>
            <option value='months'>月</option>
            <option value='years'>年</option>
          </SelectControl>
        </Col>
        <Col md={12} />
        <Col md={6}>
          <TextControl
            name='maxServers'
            label='最大同時運作數(可以安裝幾台 Patrol 伺服器)'
            hasError={hasError.maxServers}
            help={help.maxServers}
            disabled={loading}
            value={maxServers}
            onChange={(e) => setMaxServers(e.target.value)}
          />
          <TextControl
            name='macAddressList'
            label='支援的 MAC 位址清單(使用","分隔)'
            hasError={hasError.macAddressList}
            help={help.macAddressList}
            disabled={loading}
            value={macAddressList}
            onChange={(e) => setMacAddressList(e.target.value)}
          />
          <TextControl
            name='totalMaxStreams'
            label='支援的最大串流數(每個 Patrol 伺服器可以建立幾個串流)'
            hasError={hasError.totalMaxStreams}
            help={help.totalMaxStreams}
            disabled={loading}
            value={totalMaxStreams}
            onChange={(e) => setTotalMaxStreams(e.target.value)}
          />
          <ControlGroup hideLabel hideHelp>
            <Button
              type='submit'
              inputClasses={{ 'btn-success': true }}
              disabled={loading}
              onClick={handleSubmit}
            >
              建立
              <Spinner space='left' show={loading} />
            </Button>
          </ControlGroup>
        </Col>
      </Row>
    </section>
  )
}

export default CreateCertificatePage
