import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { Col, Row } from 'reactstrap'
import axios from 'axios'
import { get, deleteItem } from '../../utils/httpAgent'
import TextControl from '../../components/text-control-fillet'
import Button from '../../components/button'
import Spinner from '../../components/spinner'

class CertificatesPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0,
      pageSize: 10,
      sorted: [{ id: 'updateTime', desc: true }],
      search: ''
    }
    this.handleFetchData = this.handleFetchData.bind(this)
  }

  search () {
    this.handleFetchData({
      page: this.state.page,
      pageSize: this.state.pageSize,
      sorted: this.state.sorted,
      search: this.state.search
    })
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 0
    const pageSize = state ? state.pageSize : 10
    const search = this.state.search || ''
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      page: state.page,
      pageSize,
      sorted: state.sorted,
      loading: true
    })

    get(`/1/admin/certificates?page=${page}&limit=${pageSize}&sort=${sorted}&search=${search}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteData (id, name) {
    if (!window.confirm(`是否確定要刪除 ${name} 的憑證資訊 ？`)) {
      return -1
    }

    deleteItem(`/1/admin/certificate/${id}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchData({
            page: this.state.page,
            pageSize: this.state.pageSize,
            sorted: this.state.sorted
          })
        }
      })
  }

  downloadCertificate (id) {
    axios({
      url: `/1/account/certificate/${id}/download`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'certificate.json')
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  render () {
    const columns = [
      {
        Header: '',
        accessor: '_id',
        width: 80,
        Cell: row => (
          <>
            <Link to={`/app/admin/certificate/${row.value}`} className='btn btn-sm'>
              <i className='lnr lnr-pencil' />
            </Link>
            <button
              // href={`/1/account/certificate/${row.value}/download`}
              className='btn btn-sm'
              onClick={this.downloadCertificate.bind(this, row.value)}
            >
              <i className='lnr lnr-download' />
            </button>
          </>
        )
      }, {
        Header: '公司',
        accessor: 'company',
        Cell: row => (
          <span>{row.value ? row.value.name : ''}</span>
        )
      }, {
        Header: '個人',
        accessor: 'users',
        Cell: row => (
          <span>{row.value.map(u => u.name).join(', ')}</span>
        )
      }, {
        Header: '到期期間',
        accessor: 'licenseDuration'
      }, {
        Header: '同時運作數',
        accessor: 'maxServers'
      }, {
        Header: '最大串流數',
        accessor: 'totalMaxStreams'
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            {row.value === true
              ? '是'
              : row.value === false
                ? '否'
                : '異常'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '創建日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button
            className='btn btn-danger btn-sm'
            onClick={
              this.deleteData.bind(
                this,
                row.value,
                row.original.company ? row.original.company.name : row.original.users.map(u => u.name).join(', ')
              )
            }
          >
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            Patrol 使用憑證管理
          </title>
        </Helmet>

        <h1 className='page-header'>Patrol 使用憑證管理</h1>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Link to='/app/admin/create_certificate' className='btn btn-success'>
              建立憑證
            </Link>
            <TextControl
              groupClasses={{ 'form-group-inline max-width-125 ml-5': true }}
              name='search'
              value={this.state.search}
              placeholder='公司名、帳號、email'
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <Button
              type='button'
              inputClasses={{ 'btn btn-primary ml-1 mb-1': true }}
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </Col>
          <Col md={12}>
            <ReactTable
              keyField='_id'
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              loadingText='資料載入中，請稍候...'
              className='-striped -highlight'
              defaultPageSize={this.state.pageSize}
              defaultSorted={[{ id: 'createTime', desc: true }]}
            />
          </Col>
        </Row>
      </section>
    )
  }
}

export default CertificatesPage
